import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../utils/windowDimention';
import Loader from "../../../Components/Common/Loader";
import InnerPageHeader from '../../../Components/InnerPageHeader';
import {formatNumberWithComma} from '../../../utils/utils';
import { io } from "socket.io-client";
import rxSearch from '../../../assets/images/common-images/search.png';
import rxPlus from '../../../assets/images/common-images/plusIcon_red.png';
import SwipeToDelete from 'react-swipe-to-delete-component';
import Moment from 'moment';
import Logo from '../../../assets/images/logo/rx_main_logo.png';
import CheckButton from '../../../assets/images/common-images/checkIcon.png';
import MyButton from '../../../Components/Common/MyButton';
import RecordOn from '../../../assets/images/common-images/recordOn.png';
import RecordOff from '../../../assets/images/common-images/recordOff.png';
import ID from '../../../assets/images/common-images/id.png';
import IDOFF from '../../../assets/images/common-images/idOff.png';
import RiderWith1 from '../../../assets/images/common-images/riderWith1.png';
import BackArrow from '../../../assets/images/common-images/backArrowGray.png';
import Prescription from '../../../assets/images/common-images/prescriptions.png';
import Orderlist from '../../../assets/images/common-images/orderlist.png';
import PrescriptionGray from '../../../assets/images/common-images/prescriptionsgray.png';
import OrderlistGray from '../../../assets/images/common-images/orderlistgray.png';
import CheckBoxCheck from '../../../assets/images/common-images/checkBoxCheck.png';
import Autocomplete from "react-google-autocomplete";
import TIPID_ID from '../../../assets/images/common-images/tipid_id.png';
import TIPID_IDOFF from '../../../assets/images/common-images/tipid_idOff.png';

import { OTSession, OTPublisher, OTSubscriber, OTStreams } from 'opentok-react';

import Api from '../../../services/Api';
import './style.css';

import {
  updateReferror, sendSocketCommand, getActiveCall, getDeliveryTypes, searchBrandOrGeneric, getCart, getDiscountTypesPerDrugstoreRx, getDrugstoreRxNeutral,
  updateCart, endCall, updateRxInfo, getDrugstoreRx, uploadBought, getAlphaBought, getBoughtHistory, searchUserWithAddress, registerRx, checkPhoneNumber, getScripts, getSteps
} from "../../../services/ApiService";
import { ids, routes } from '../../../constants';
import { isIOS } from 'react-device-detect';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const socket = io.connect(process.env.REACT_APP_SOCKET_URL);

socket.on("connect", (data) => {
  // console.log(`socket.data=${JSON.stringify(data)}`)  
})

const GetOrder = ({ }) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [loader, setLoader] = useState(false);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [user, setUser] = useState({firstname: "", lastname: "", address: ""});
  const [isSearchReferror, setIsSearchReferror] = useState(false);
  const [referror, setReferror] = useState({id: 0});
  const [showSearchReferror, setShowSearchReferror] = useState();
  const [showSearchMember, setShowSearchMember] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const [deliveryTypes, setDeliveryTypes] = useState();
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [selectedDeliveryType, setSelectedDeliveryType] = useState();
  const [showSearchProduct, setShowSearchProduct] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedSkus, setSelectedSkus] = useState([]);
  const [isFinalize, setIsFinalize] = useState(false);
  const [discountTypes, setDiscountTypes] = useState([]);
  const [dsData, setDsData] = useState([]);
  const [showSms, setShowSms] = useState(false);
  const [showMayWe, setShowMayWe] = useState(true);
  const [showText, setShowText] = useState(false);
  const [textToShow, setTextToShow] = useState("");
  const [subTextToShow, setSubTextToShow] = useState("");
  const [imageToShow, setImageToShow] = useState("");
  const [currentItem, setCurrentItem] = useState();
  const [showNewCart, setShowNewCart] = useState(false);
  const [showFinalCost, setShowFinalCost] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showFinalize, setShowFinalize] = useState(false);
  const [addedItem, setAddedItem] = useState(false);
  const [showSelectDelivery, setShowSelectDelivery] = useState(false);
  const [shownDelivery, setShownDelivery] = useState(false);
  const [updatedUserDetails, setUpdatedUserDetails] = useState(false);
  const [showUpdateUserDetails, setShowUpdateUserDetails] = useState(false);
  const [shownDiscount, setShownDiscount] = useState(false);
  const [selectedDs, setSelectedDs] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [discountTypesData, setDiscountTypesData] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [alreadyBought, setAlreadyBought] = useState(false);
  const [isRecordOn, setIsRecordOn] = useState(false);
  const [selectedScriptIndex, setSelectedScriptIndex] = useState(20);
  const [selectedStepIndex, setSelectedStepIndex] = useState(Number(process.env.REACT_APP_IS_MD_CLUB) == 1 ? 22 : 1);
  const [showEditItem, setShowEditItem] = useState(false);
  const [showCapture, setShowCapture] = useState(false);
  const [showIsReseta, setShowIsReseta] = useState(false);
  const [resetaImage, setResetaImage] = useState();
  const [showIsOrderlist, setShowIsOrderlist] = useState(false);
  const [orderlistImage, setOrderlistImage] = useState();
  const [showDownload, setShowDownload] = useState(false);
  const [showDownload2, setShowDownload2] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [showReorder, setShowReorder] = useState(false);
  const [showOrderlist, setShowOrderlist] = useState(false);
  const [showAlphalist, setShowAlphalist] = useState(false);
  const [list, setList] = useState([]);
  const [alphalist, setAlphalist] = useState([]);
  const [tabArr, setTabArr] = useState([
    { tabId: 1, tabName: 'Order list', isSelect: true },
    { tabId: 2, tabName: 'Alpha list', isSelect: false }
  ])
  const [tabIndex, setTabIndex] = useState(0);
  const [value, setValue] = useState();
  const [idOn, setIdOn] = useState(false);
  const [scPwdIdUrl, setScPwdIdUrl] = useState();
  const [controlType, setControlType] = useState(0);
  const [finalized, setFinalized] = useState(false);
  const [members, setMembers] = useState([]);
  const [showSummaryList, setShowSummaryList] = useState(false);
  const [showAddMember, setShowAddMember] = useState(false);
  const [tipidIdOn, setTipidIdOn] = useState(false);
  const [tipidCardIdUrl, setTipidCardIdUrl] = useState();
  const [showProductImage, setShowProductImage] = useState(false);
  const [script, setScript] = useState([]);
  const [steps, setSteps] = useState([]);

  const date = new Date(Moment(new Date()).add(1, 'hour'));
  const props = useLocation().state;

  // const script = [
  //   {
  //     id: 20,
  //     text: process.env.REACT_APP_IS_MD_CLUB ? `Good Morning!\nWelcome po sa Tipid Club\n\nAno pong pangalan nyo?` :  `Good Morning!\nWelcome po sa RxClub`
  //   },
  //   {
  //     id: 1,
  //     text: `Ano pong order nyo?`
  //   },
  //   {
  //     id: 2,
  //     text: `Meron pa po?`
  //   },
  //   {
  //     id: 3,
  //     text: process.env.REACT_APP_IS_MD_CLUB ? `Sir/Ma'am ulitin ko lang po order nyo _______` : `Paki confirm po kung tama ang lahat ng order` 
  //   },
  //   {
  //     id: 4,
  //     text: process.env.REACT_APP_IS_MD_CLUB ? `Bali Sir/Maam may delivery charge po tayo 100 pesos po 1 hour delivery` : `Sir/Ma'am Express o Regular Delivery`
  //   },
  //   {
  //     id: 5,
  //     text: process.env.REACT_APP_IS_MD_CLUB ? `Sir/Maam ano pong address nyo para sa delivery?\nCellphone number po?` : `Sir/Ma'am pwede po malaman First Name, Last Name nyo?\nCellphone number po?`
  //   }, 
  //   {
  //     id: 6,
  //     text: `Sir/Ma'am address po para sa delivery\nMay SC or PWD Card po? RX Tipid Card po?`
  //   },
  //   {
  //     id: 7,
  //     text: `(Click DISCOUNTS)`
  //   },
  //   {
  //     id: 8,
  //     text: `Sir/Ma'am eto po lahat ang nakuha nyong discount mahigit ₱__________`
  //   },
  //   {
  //     id: 9,
  //     text: `Sir/Ma'am eto po lahat ng total na babayaran nyo`
  //   },
  //   {
  //     id: 10,
  //     text: `Sir/Ma'am nakatipid po kayo ng mahigit sa ₱________ sa order nyo`
  //   },
  //   {
  //     id: 11,
  //     text: `Sir/Ma'am wait na lng po sa text ng RxClub para matrack ang iyong order\n\nThank you, order po ulit kayo sa RxClub`
  //   },
  //   {
  //     id: 12,
  //     text: `(Click SAVE)`
  //   }, 
  //   {
  //     id: 13,
  //     text: `Paki-tap po ung Prescription/Order List button at piliin ang image.\n\nOorderin po ba lahat ng nasa listahan?`
  //   },
  //   {
  //     id: 14,
  //     text: `Sir/Ma'am, para ma track ang delivery ng order nyo at kunin na rin natin ang next P300 promo code nyo, i download lang ang app\n\nMadali lang po`
  //   },
  //   {
  //     id: 15,
  //     text: `Welcome po sa RxClub.\n\nEto pa rin po order nyo?`
  //   },
  //   {
  //     id: 16,
  //     text: process.env.REACT_APP_IS_MD_CLUB ? `Tama po? (Banggitin name ng product after ma-search ang hinahanap na gamot)` : `Pacheck po sa screen kung tama, pascroll-up po para makita ang picture ng gamot`
  //   },
  //   {
  //     id: 17,
  //     text: `Sir/Ma'am may nagrefer po ba sa inyo dito?`
  //   },
  //   {
  //     id: 18,
  //     text: `Sir/Ma'am sundin lamang ang "Promo Code Steps" na makikita nyo ngayon sa inyong screen\n\nThank you for buying in RxClub\n\nSee you in our App\n\nBye!`
  //   },
  //   {
  //     id: 19,
  //     text: `If exists: Sir/Ma'am 2nd Call nyo na po sa amin. Dahil dyan, bibigyan namin kayo ng 300 pesos promo code ng RxClub. Makakatanggap kayo ng text message - pindutin lamang ang link para magamit ang Promo Code sa inyong pagbili`
  //   }
  // ];

  // const steps = [
  //   {
  //     id: 1,
  //     text: `Tap "Order-Taking"`
  //   },
  //   {
  //     id: 2,
  //     text: `Tap "Add Item"`
  //   },
  //   {
  //     id: 3,
  //     text: `Tap "Search bar"\nType Item letters\nthen\nClick desired item`
  //   },
  //   {
  //     id: 4,
  //     text: `Edit quantity\nthen tap "Save"`
  //   },
  //   {
  //     id: 5,
  //     text: `If yes, tap "Add Item"\nIf no, tap "Finalize"`
  //   },
  //   {
  //     id: 6,
  //     text: `If yes, tap "Back Arrow"\nIf no, tap "Add Item"\nor "Edit Item"\nif needed`
  //   },
  //   {
  //     id: 7,
  //     text: `Tap "Delivery Fee"`
  //   },
  //   {
  //     id: 8,
  //     text: process.env.REACT_APP_IS_MD_CLUB ? `Tap "Express" then tap "Select"` : `Tap "Express" or "Regular"\nthen tap "Select"`
  //   },
  //   {
  //     id: 9,
  //     text: `Tap "Member Info"`
  //   },
  //   {
  //     id: 10,
  //     text: `Tap "Save" after input`
  //   },
  //   {
  //     id: 11,
  //     text: `Tap "Save" after input\nThen tap "Back"`
  //   },
  //   {
  //     id: 12,
  //     text: `If yes, tap "Referral"\nIf no, tap "Discount"`
  //   },
  //   {
  //     id: 13,
  //     text: `Tap "Summary List"`
  //   },
  //   {
  //     id: 14,
  //     text: `Tap "Show"`
  //   },
  //   {
  //     id: 15,
  //     text: `Tap "Back Arrow"`
  //   },
  //   {
  //     id: 16,
  //     text: `Tap "Confirm"`
  //   },
  //   {
  //     id: 17,
  //     text: `Tap "Capture"`
  //   },
  //   {
  //     id: 18,
  //     text: `Tap "Download"`
  //   },
  //   {
  //     id: 19,
  //     text: `Tap "Steps"`
  //   },
  //   {
  //     id: 20,
  //     text: `Tap "X"`
  //   },
  //   {
  //     id: 21,
  //     text: `Tap "Discount"`
  //   },
  //   {
  //     id: 22,
  //     text: `Tap "Search" icon`
  //   },
  //   {
  //     id: 23,
  //     text: `If not, tap "Plus" button`
  //   },
  // ]

  // //20221216
  const apiKey = '47701341';
  //const sessionId = '1_MX40NzYzNzk0MX5-MTY3MjgxODQyMDY2OX4yRUl3S1Fwdm1xUk5oYWU0elZWM0xLOVl-fn4';
  // const token = 'T1==cGFydG5lcl9pZD00NzYyODc5MSZzaWc9MzJiMDM0MmVkZDc3ZWFmNzVkZDgwYjdlZjE0NDA0MTJhNGVlMjAyYzpzZXNzaW9uX2lkPTFfTVg0ME56WXlPRGM1TVg1LU1UWTNNVEUxTnpVek1ESXpOMzVwTUc5bFUwRkZlWE4zUjBObmMxTkhja1F2VkhGWVJsVi1mbjQmY3JlYXRlX3RpbWU9MTY3MTE1NzU1NyZub25jZT0wLjMyMDI0NjM5Nzc1MjU4NDQ2JnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2NzM3NDk1NTYmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=';
  const sessionId = props.sessionId;
  const token = props.token;
  
  const w = 400;
  const h = 470;

  useEffect(() => {
    getScripts({})
    .then(({data}) => {
      setScript(data.map(item => {
        return {
          id: item.id,
          text: item.name
        }
      }));
    })
    .catch(e => {
      console.warn('get script ERROR', e.message);
    });
    getSteps({})
    .then(({data}) => {
      setSteps(data.map(item => {
        return {
          id: item.id,
          text: item.name
        }
      }));
    })
    .catch(e => {
      console.warn('get steps ERROR', e.message);
    });
  }, [])

  useEffect(() => {
    console.log(`script=${JSON.stringify(script)}`)
    if (script.length > 0) {
      setSelectedScriptIndex(20);
    }
  }, [script])

  useEffect(() => {
    console.log(`steps=${JSON.stringify(steps)}`)
    if (steps.length > 0) {
      setSelectedStepIndex(1);
    }
  }, [steps])

  useEffect(() => {
    if (props.callType == 1) {
      setSelectedScriptIndex(15)
    }
  }, [])

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    if (typeof scPwdIdUrl !== 'undefined' && scPwdIdUrl) {
      const tempUser = {...user}
      tempUser.scPwdIdUrl = scPwdIdUrl
      setUser(tempUser)
    }
  }, [scPwdIdUrl])

  useEffect(() => {
    if (typeof tipidCardIdUrl !== 'undefined' && tipidCardIdUrl) {
      const tempUser = {...user}
      tempUser.tipidCardIdUrl = tipidCardIdUrl
      setUser(tempUser)
    }
  }, [tipidCardIdUrl])
  
  useEffect(() => {
    _loadData()
    window.history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function(event) {
      console.log('hello')
      _onBackPress();
    });
    console.log(`getorder.userData=${JSON.stringify(props.userData)}`)
    socket.emit('online',  {userId : props.userData.userId});
    socket.on('memberEndCall', function (data){
      console.log(`memberEndCall; data=${data}`)
      _onBackPress();
    });
    socket.on('sendFile', function (data) {
      console.log(`sendFile; data=${data}`)
      const arr = data.split(',')
      if (arr.length == 2) {
        const fileId = Number(arr[0])
        if (fileId == 1) { //reseta
          setShowIsReseta(true)
          setResetaImage(arr[1])
        }
        else if (fileId == 2) {
          setShowIsOrderlist(true)
          setOrderlistImage(arr[1])
        }
        else if (fileId == 3) { //sc/pwd id
          setScPwdIdUrl(arr[1])
        }
        else if (fileId == 4) { //tipid card id
          setTipidCardIdUrl(arr[1])
        }
        setShowMayWe(false)
      }
    })
  }, [])

  const _loadData = () => {
    getActiveCall({})
    .then(({data}) => {
      _onSaveButtonPress(data.data.userId || data.data.id);
      setTimeout(() => {
        _onSelectPress(data.data)
      }, 1000)
    })
    .catch(e => {
      console.warn('get active call ERROR', e.message);
    });
    getDeliveryTypes({})
    .then(({data}) => {
      const selectedDeliveryType = data.data.filter(item => item.isSelected)[0];
      setDeliveryTypes(data.data);
      setDeliveryFee(selectedDeliveryType.price);
      setSelectedDeliveryType(selectedDeliveryType);
    })
    .catch(e => {
      console.warn('get delivery types ERROR', e.message);
    });
  }

  const _renderScriptItem = (item,index) => {
    return <p key={`script_${index}`} style={{width: 300, fontSize: 38, color: '#404040', marginTop: 10, textAlign: 'center', lineHeight: 1.2}}><div className="new-line">{item.text}</div></p>
  }

  const _renderToDoItem = (item,index) => {
    return <p key={`todo_${index}`} style={{width: 300, fontSize: 32, color: '#404040', marginTop: 10, textAlign: 'center', fontStyle: 'italic', lineHeight: 1.2}}><div className="new-line">{item.text}</div></p>
  }

  const _onSendSocket = (text, subText, command, data) => {
    console.log(`command=${command}, data=${data}`)
    const params = data ?  {
      userId: user.id,
      command: command,
      data: data
    } : { 
      userId: user.id,
      command: command 
    }
    
    sendSocketCommand(params)
    .then(({data}) => {
    })
    .catch(e => {
      console.warn('send socket ERROR', e.message);
    });
  }

  useEffect(() => {
    if (showFinalCost) {
      _onDrugstorePress(true);
    }
  }, [showFinalCost])

  const _getCart = (userId) => {
    getCart({userId})
    .then(({data}) => {
      setSelectedSkus(data.data.map((item) => {
        return {
          ...item,
          isSelected: true
        }
      }))
      getDiscountTypesPerDrugstoreRx({userId: userId})
      .then(({data}) => {
        setDiscountTypes(data);
        getDrugstoreRxNeutral({})
        .then(({data}) => {
          setDsData(data.data);
          _onDrugstorePress(false);
        })
        .catch(e => {
          console.warn('get drugstore ERROR', e.message);
        });
      })
      .catch(e => {
        console.warn('get discount types ERROR', e.message);
      });
    })
    .catch(e => {
      console.warn('get cart ERROR', e.message);
    });
  }

  const _onSelectPress = (rowData) => {
    let result = {id: rowData.userId || rowData.id, 
      firstname: rowData.firstname,
      lastname: rowData.lastname,
      displayName: rowData.displayName, 
      avatarBig: rowData.avatarBig, 
      avatarSmall: rowData.avatarSmall,
      address: rowData.address,
      phoneNumber: rowData.phoneNumber,
      scPwdId: rowData.scPwdId,
      scId: rowData.scId,
      pwdId: rowData.pwdId,
      scPwdIdUrl: rowData.scPwdIdUrl,
      Referror: rowData.Referror,
      hasTipidCard: rowData.hasTipidCard,
      tipidCardId: rowData.tipidCardId,
      tipidCardIdUrl: rowData.tipidCardIdUrl,
    };
    console.log(`rowData=${JSON.stringify(rowData)}`)
    console.log(`result=${JSON.stringify(result)}`)
    if (isSearchReferror) {
      setReferror(result);
      setSearchText("");
      setShowSearchReferror(false);
      setShowSearchMember(false);
      setShowUserDetails(true);
      setSelectedStepIndex(21);
      setIsSearchReferror(false);
      setMembers([]); 
      setSearchText("");
      setUserReferror(result);
      if (typeof result !== 'undefined' && typeof result.id !== 'undefined') {
        updateReferror({
          userId: user.id,
          referredById: result.id
        })
        .then(({data}) => {
          setIsEdited(false);
          setEnableSave(false);
          _onSendSocket('Info', 'saved', "updateUserDetails", user.phoneNumber)        
        })
        .catch(e => {
          console.warn('update referror ERROR', e.message);
        });
      }
    }
    else {
      if (result.scId !== null) {
        setValue("SC")
      }
      else if (result.pwdId !== null) {
        setValue("PWD")
      }
      setSearchText("");
      setUser(result);
      setShowSearchMember(false);
      setMembers([]);
      setReferror(rowData.Referror);
      setIsEdited(false);
      _getCart(rowData.userId);
    }
  }

  const onSearchChange = async (searchText) => {
    if (searchText.trim() == "") {
      setMembers([]);
      setSearchText(searchText);
    }
    else {
      const response = await searchUserWithAddress({search: encodeURI(searchText.replace(' ', '+'))});
      // console.log(`response=${JSON.stringify(response)}`)
      if (response) {
        if (isSearchReferror && typeof user.id !== 'undefined') {
          setMembers(response.data.data.filter(item => item.id !== user.id));
        }
        else if (typeof user.id !== 'undefined'){
          setMembers(response.data.data.filter(item => item.id !== user.id && item.id !== referror.id));
        }
        else {
          setMembers(response.data.data);
        }
        setSearchText(searchText);
      }
    }
  }

  const renderMemberItem = (rowData,index) => {
    const width = 500;
    console.log(`rowData=${JSON.stringify(rowData)}`)
    return <div onClick={() => _onSelectPress(rowData)}>
      <div style={{flex: 1,
        display: 'flex',
        flexDirection: 'row',
        minHeight: 60,
        alignItems: 'center',
        marginLeft: 10,
        width: width - 30, height: 40,
        cursor: 'pointer'
        }} 
      >
        <p style={{fontFamily: 'CanaroBook',
          fontSize: 16,
          color: '#9be0dd',
          backgroundColor: 'transparent',
          paddingLeft: 10,
          paddingRight: 10,
          textAlign: 'left',
          marginLeft: 5
        }}>{rowData.firstname} {rowData.lastname}</p>
        <p style={{marginLeft: 0, color: '#404040'}}>({rowData.phoneNumber})</p>
      </div>
    </div>
  }

  const onSearchChangeProduct = async (searchText) => {
    if (searchText.trim() == "") {
      setProducts([]);
      setSearchText(searchText);
    }
    else {
      const response = await searchBrandOrGeneric({search: encodeURI(searchText.replace(' ', '+'))});
      // console.log(`response=${JSON.stringify(response)}`)
      if (response) {
        setProducts(response.data.data);
        setSearchText(searchText);
      }
    }
  }

  const renderProductItem = (rowData,index) => {
    const width = 500;
    console.log(`rowData=${JSON.stringify(rowData)}`)
    return <div onClick={() => _onSelectProductPress(rowData)}>
      <div style={{flex: 1,
        display: 'flex',
        flexDirection: 'row',
        minHeight: 60,
        alignItems: 'center',
        marginLeft: 10,
        width: width - 30, height: 40,
        cursor: 'pointer'
        }} 
      >
        <p style={{ width: width - 100, fontFamily: 'CanaroBook',
          fontSize: 16,
          color: '#404040',
          backgroundColor: 'transparent',
          paddingLeft: 10,
          paddingRight: 10,
          textAlign: 'left',
          marginLeft: 5
        }}>{rowData.brand.name} {rowData.dosage.name} ({rowData.generic.name})</p>
        <p style={{ textAlign: 'right', width: 50, fontSize: 16}}>₱{formatNumberWithComma(rowData.brand.price,2)}</p>
      </div>
    </div>
  }

  const _onSelectProductPress = (rowData) => {
    let result = {
      ...rowData,
      quantity: 1
    };
    if (selectedSkus.filter(item => item.brand.id == rowData.brand.id).length == 0) {
      const selectedSkus1 = [...selectedSkus];
      selectedSkus1.push(result)
      setSelectedSkus(selectedSkus1)
      setShowSearchProduct(false);
      setSearchText("");
      setProducts([]);
      setIsFinalize(false);
      setEnableSave(true);
      setTextToShow(`${rowData.brand.name} ${rowData.dosage.name} (${rowData.generic.name})`);
      setSubTextToShow(`₱${formatNumberWithComma(rowData.brand.price,2)}`);
      setImageToShow(rowData.brand.image)
      setShowText(true);
      setShowMayWe(false);
      setCurrentItem(result);
      _onDrugstorePress(false);
      _onSendSocket('Medicine', 'added', 'addMedicine', rowData.brand.id);
      setSelectedScriptIndex(16);
      setSelectedStepIndex(4);
      // setShowIsReseta(false);
      // setShowIsOrderlist(false); 
      setShowProductImage(true);
    }
  }

  const _onDrugstorePress = async (showFinalCost) => {
    const response = await getDrugstoreRx();
    if (response) {
      if (response.data.data.length > 0) {
        setDsData(response.data.data);
        let data = [];
        // console.log(`dsData=${JSON.stringify(response.data.data)}`)
        // console.log(`sku=${JSON.stringify(sku)}`)
        response.data.data.map(store => {
          let total = 0;
          let details = [];      
          selectedSkus.filter(item => item.quantity > 0).map(selected => {
            // const storeItem = store.details.find(i => i.generic.id == selected.generic.id && i.dosage.id == selected.dosage.id)
            // const storeItem = this.state.isGeneric ? store.details.find(i => i.generic.id == selected.generic.id && i.dosage.id == selected.dosage.id) : store.details.find(i => i.brand.id == selected.brand.id)
            const storeItem = store.details.find(i => i.brand.id == selected.brand.id)
            if (storeItem) {
              total += storeItem.brand.price * selected.quantity;
              details.push({
                ...storeItem,
                quantity: selected.quantity
              })
            }
          })
          data.push({
            ...store,
            total,
            details: [...details]
          })
        })
        ///// buy now
        const selected = data.filter(item => !item.hasBorder)
        console.log(`selected=${JSON.stringify(selected)}`)
        if (selected.length > 0) {
          _onDsRowPress(selected[0], showFinalCost);
        }   
      }
    } 
  }

  const _onDsRowPress = (rowData, showFinalCost) => {
    // console.log(`rowData=${JSON.stringify(rowData)}`)
    // console.log(`discountTypes=${JSON.stringify(discountTypes)}`)
    const totalPriceHere = rowData.details.reduce((a,v) =>  a = a + v.quantity * v.brand.price, 0);
    // console.log(`totalPriceHere=${totalPriceHere}`)
    let totalDiscountHere = 0;
    const tempDT2 = discountTypes.filter(item => item.drugstore_id == rowData.id && item.is_volume == 1 && totalPriceHere >= item.volume)
      .sort((a, b) => a.volume < b.volume ? 1 : -1)
    let tempDT3 = [...tempDT2.slice(0,1), ...discountTypes.filter(item => item.drugstore_id == rowData.id && item.is_volume == 0)].sort((a, b) => a.sort_order > b.sort_order ? 1 : -1);
    // console.log(`tempDT3=${JSON.stringify(tempDT3)}`)
    const tempDT = tempDT3.map(item => {
      if (rowData.discount == 0 && item.id == 1) { //no member discount
        //force amount to 0 for later filtering
        return {
          ...item,
          amount: 0
        }
      }
      else {
        if (item.id == ids.discountTypeTipidCard) { //tipid card discount
          // console.log(`selectedSkus=${JSON.stringify(selectedSkus)}`)
          const disc = selectedSkus.filter(item => item.brand.discountPercentage > 0).reduce((a,v) =>  a = a + v.quantity * v.brand.price * v.brand.discountPercentage, 0 );
          totalDiscountHere += disc;
          return {
            ...item,
            discountValue: disc,
            subTotal: totalPriceHere - disc,
            isUsed: true
          }
        }
        else if (item.is_percentage == 1 && totalPriceHere > totalDiscountHere + (totalPriceHere - totalDiscountHere) * item.value) { //percentage
          const seniorDiscount = (totalPriceHere - totalDiscountHere) * item.value;
          totalDiscountHere += seniorDiscount;
          return {
            ...item,
            discountValue: seniorDiscount,
            subTotal: totalPriceHere - totalDiscountHere,
            isUsed: true
          }
        }
        else if (item.is_percentage == 0 && totalPriceHere > totalDiscountHere + item.amount) {
          totalDiscountHere += item.amount;
          return {
            ...item,
            discountValue: item.amount,
            subTotal: totalPriceHere - totalDiscountHere,
            isUsed: true
          }
        }
        else {
          return {
            ...item,
            isUsed: false
          }
        }
      }
    }).filter(item => item.isUsed);
    
    // console.log(`tempDT=${JSON.stringify(tempDT)}; totalDiscount=${totalDiscountHere}`)
    console.log(`shownDiscount=${shownDiscount}; showFinalCost=${showFinalCost}`)
    if (shownDiscount) {
      setSelectedDs(rowData);
      setTotalPrice(totalPriceHere);
      setTotalDiscount(totalDiscountHere);
      setDiscountTypesData(tempDT);
      setShowFinalCost(true);
      setShowDiscount(false);
      setShowNewCart(false);
    }
    else {
      setSelectedDs(rowData);
      setTotalPrice(totalPriceHere);
      setTotalDiscount(totalDiscountHere);
      setDiscountTypesData(tempDT);
      setShowFinalCost(false);
    }
  }

  const onQuantityChange = (rowData, quantity) => {
    if (Number(quantity) < 1000) {
      let tempGamot = [...selectedSkus
      .map(item => {
        // if (item.generic.id == rowData.generic.id && item.dosage.id == rowData.dosage.id && item.quantity < 100) {
        if (item.brand.id == rowData.brand.id) {
          return {
            ...item,
            quantity: Number(quantity)
          }
        }
        else {
          return item
        }
      })];
      setSelectedSkus(tempGamot);
      let c = {...currentItem}
      c.quantity = Number(quantity)
      setCurrentItem(c);
    }
  }

  const onDelete = (customProps) => {
    console.log(`selectedSkus=${JSON.stringify(selectedSkus)}; customerProps=${JSON.stringify(customProps)}`)
    const rowData = customProps.item;
    const selectedSkus1 = [...selectedSkus].filter(item => item.brand.id !== rowData.brand.id);
    setSelectedSkus(selectedSkus1);
  }

  const _onSaveButtonPress = (userId) => {
    updateCart({
      userId: userId,
      details: selectedSkus.filter(item => item.quantity > 0).map((item, index) => {
        return {
          brandedId: item.brand.id,
          quantity: item.quantity,
          sortOrder: index
        }
      }),
      eta: new Date(date).toISOString().slice(0,-5)
    })
    .then(({data}) => {
      setSelectedSkus(selectedSkus.filter(item => item.quantity > 0))
      setEnableSave(false);  
      setShowText(false);
      setShowNewCart(true);    
      setAddedItem(true);
      setShowProductImage(false);
    })
    .catch(e => {
      console.warn('update cart ERROR', e.message);
    });
  }

  const _renderCurrentItem = (item, index) => {
    const maxText = 35;
    const truncate = (input) => input.length > maxText ? `${input.substring(0, maxText)}...` : input;
    console.log(`item=${JSON.stringify(item)}`)
    let name = truncate(`${item.brand.name} ${item.dosage.name} (${item.generic.name})`);
    return <div style={{marginTop: 230}}>
      <div key={`currentItem_${index}`} >
        <>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 1.25, height: 30, marginBottom: 5}}>
            <p style={{maxHeight: 20, left: 0, color: '#404040', fontSize: 18, width: w * 1.25, textAlign: 'left', fontWeight: '600'}} >{name}</p>
            {/* <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginLeft: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
            <p style={{fontWeight: '500', fontSize: 11, marginLeft: 1, color: '#404040', textAlign: 'right'}}>*</p> */}
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: w * 1.25, height: 30, marginBottom: 15}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: w * 0.95}}>
              {/* <p style={{color: '#9be0dd', fontSize: 18, textAlign: 'left', marginTop: 5}} >Brand:</p> */}
              <form style={{marginBottom: 5}} onSubmit={e => { e.preventDefault(); }}>
                <input type="numeric" style={{width: 50, fontSize: 16, textAlign: 'center'}} value={item.quantity} 
                  onChange={(event) => {
                    if (event.target.key === 'Enter') {
                      event.preventDefault();
                    }
                    else {
                      onQuantityChange(item, event.target.value)}
                  }}
                  maxLength="3"
                />
              </form>
              <p style={{color: '#404040', fontSize: 18, textAlign: 'left', marginTop: 5, marginLeft: 5}} >@ ₱{formatNumberWithComma(item.brand.price, 2)}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: w * 0.35, height: 30, marginTop: 0}}>
              <p style={{color: '#404040', fontSize: 18, textAlign: 'right'}} >₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
            </div>
          </div> 
        </>
      </div>
    </div>
  }

  const _onBackPress = () => {
    _onSendSocket("End", "Call", "endCall", null);
    endCall({})
    .then(({data}) => {
    })
    .catch(e => {
      console.warn('end call ERROR', e.message);
    });
    navigate(routes.home)
  }

  const sendFinalize = (show) => {
    console.log(`addedItem=${addedItem}`)
    if (addedItem) {
      _onDrugstorePress(true);
      setShowFinalize(true);
      setShowIsReseta(false);
      setShowIsOrderlist(false);
      setImageToShow("");
      _onSendSocket('Finalize', '', 'finalCart', null)
      setTimeout(() => {
        setShowFinalize(false);
      }, 1000)
    }
  }

  const sendDiscount = () => {
    console.log(`updatedUserDetails=${updatedUserDetails}`)
    if (updatedUserDetails) {
      setShowDiscount(true);
      setShownDiscount(true);
      setShowNewCart(false);
      setShowUpdateUserDetails(false);
      _onSendSocket('Show', 'Discount', 'showDiscount', null)
    }
  }

  const _onDeliveryTypePress = (rowData) => {
    let tempGamot = [...deliveryTypes
    .map(item => {
      if (item.id == rowData.id) {
        return {
          ...item,
          isSelected: true
        }
      }
      else {
        return {
          ...item,
          isSelected: false
        }
      }
    })];
    setDeliveryTypes([...tempGamot]);
  }

  const _onSelectDelFee = () => {
    const selected = deliveryTypes.filter(item => item.isSelected)[0]
    setShowSelectDelivery(false);
    setShownDelivery(true);
    setDeliveryFee(selected.price);
    setSelectedDeliveryType(selected);
    setTextToShow(`DELIVERY FEE: (${selected.name})`);
    setSubTextToShow(`₱${formatNumberWithComma(selected.price,2)}`);
    setShowMayWe(false);
    setShowText(true);
    setImageToShow("");
    setShowUserDetails(false);
    setShowDiscount(false);
    _onSendSocket('Delivery Fee', 'saved', 'setDelivery', selected.id);
  }

  const setFirstName = (firstname) => {
    const tempUser = {...user}
    tempUser.firstname = firstname
    setUser(tempUser)
  }

  const setLastName = (lastname) => {
    const tempUser = {...user}
    tempUser.lastname = lastname
    setUser(tempUser)
  }

  const setAddress = (address) => {
    const tempUser = {...user}
    tempUser.address = address
    setUser(tempUser)
  }

  const setPhoneNumber = (phoneNumber) => {
    const tempUser = {...user}
    tempUser.phoneNumber = phoneNumber
    setUser(tempUser)
  }

  const setScPwdId = (scPwdId) => {
    const tempUser = {...user}
    tempUser.scPwdId = scPwdId
    setUser(tempUser)
  }

  const setTipidCardId = (tipidCardId) => {
    const tempUser = {...user}
    tempUser.tipidCardId = tipidCardId
    setUser(tempUser)
  }

  const setUserReferror = (Referror) => {
    const tempUser = {...user}
    tempUser.Referror = {...Referror}
    setUser(tempUser)
  }

  const setHasTipidCard = () => {
    const tempUser = {...user}
    tempUser.hasTipidCard = (typeof tempUser.hasTipidCard !== 'undefined' && tempUser.hasTipidCard == 1) ? 0 : 1
    setUser(tempUser)
  }

  useEffect(() => {
    if (typeof user.id !== 'undefined') {
      if (value == "SC") {
        const tempUser = {...user}
        tempUser.scId = tempUser.scPwdId
        tempUser.pwdId = null
        setUser(tempUser)
      }
      else if (value == "PWD") {
        const tempUser = {...user}
        tempUser.scId = null
        tempUser.pwdId = tempUser.scPwdId
        setUser(tempUser)
      }
    }
  }, [value])

  const updateUser = () => {
    console.log(`here, user=${JSON.stringify(user)}`)
    updateRxInfo({
      userId: user.id,
      firstName: user.firstname,
      lastName: user.lastname,
      address: user.address,
      phoneNumber: user.phoneNumber,
      scPwdId: user.scPwdId,
      scId: value == "SC" ? user.scPwdId : null,
      pwdId: value == "PWD" ? user.scPwdId : null,
      scPwdIdUrl: user.scPwdIdUrl,
      hasTipidCard: user.hasTipidCard,
      tipidCardId: user.tipidCardId,
      tipidCardIdUrl: user.tipidCardIdUrl
    })
    .then(({data}) => {
      getDiscountTypesPerDrugstoreRx({userId: user.id})
      .then(({data}) => {
        console.log(`getDiscountTypesPerDrugstoreRx.data=${JSON.stringify(data)}`)
        setDiscountTypes(data);
        setUpdatedUserDetails(true);
        setShowMayWe(false);
        setShowDiscount(false);
        if (!shownDiscount) {
          setShowUserDetails(true);
        }
        setShowText(false);
        setShowNewCart(false);  
        _onSendSocket('Info', 'saved', "updateUserDetails", user.phoneNumber)  
        if (user.address == "") { //first time save
          setSelectedScriptIndex(6)
          setSelectedStepIndex(11)
        }   
        setTimeout(() => {
          _onDrugstorePress(false);
        }, 1000)
      })
      .catch(e => {
        console.warn('get discount types ERROR', e.message);
      });
    })
    .catch(e => {
      console.warn('update info ERROR', e.message);
    });
  }

  const renderItem = (item, index) => {
    // console.log('here')
    const maxText = 28;
    const truncate = (input) => input.length > maxText ? `${input.substring(0, maxText)}...` : input;
    let name = truncate(`${item.brand.name} ${item.dosage.name} (${item.generic.name})`);
    return <div className="list-group">
      {/* <SwipeToDelete key={index} onDelete={onDelete} item={item}> */}
        <div key={index}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.9, height: 30, marginBottom: 5}}>
            <p style={{maxHeight: 20, left: 0, color: '#404040', fontSize: 18, width: '100%', textAlign: 'left', fontWeight: '600'}} >{name}</p>
            {/* <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginLeft: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
            <p style={{fontWeight: '500', fontSize: 11, marginLeft: 1, color: '#404040', textAlign: 'right'}}>*</p> */}
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: w * 0.9, height: 30, marginBottom: 15}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: w * 0.6}}>
              {/* <p style={{color: '#9be0dd', fontSize: 18, textAlign: 'left', marginTop: 5}} >Brand:</p> */}
              <p style={{color: '#404040', fontSize: 18, textAlign: 'left', marginTop: 5}} >{item.quantity} @ ₱{formatNumberWithComma(item.brand.price, 2)}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: w * 0.3, height: 30, marginTop: 0}}>
              <p style={{color: '#404040', fontSize: 18, textAlign: 'right'}} >₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
            </div>
          </div> 
        </div>
      {/* </SwipeToDelete> */}
    </div>
  }

  useEffect(() => {
    console.log(`showConfirm=${showConfirm}; alreadyBought=${alreadyBought}`)
    if (showConfirm && !alreadyBought) {
      _buy();
    }
  }, [showConfirm])

  // console.log(`showText= ${showText} && showUserDetails= ${showUserDetails} && showNewCart= ${showNewCart} && showFinalCost= ${showFinalCost} && showDiscount= ${showDiscount} showSms=${showSms}`)

  const _buy = async () => {
    setShowFinalCost(false);
    setAlreadyBought(true);
  }

  const renderItemSwipeToDelete = (item, index) => {
    const maxText = 35;
    const truncate = (input) => input.length > maxText ? `${input.substring(0, maxText)}...` : input;
    let name = truncate(`${item.brand.name} ${item.dosage.name} (${item.generic.name})`);
    return <div className="list-group" style={{backgroundColor: 'white'}}>
      <div key={index} onDelete={onDelete} item={item}>
        <>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30, marginBottom: 5}}>
            <p style={{maxHeight: 20, left: 0, color: '#404040', fontSize: 18, width: '100%', textAlign: 'left', fontWeight: '600'}} >{name}</p>
            {/* <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginLeft: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
            <p style={{fontWeight: '500', fontSize: 11, marginLeft: 1, color: '#404040', textAlign: 'right'}}>*</p> */}
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: w * 0.95, height: 30, marginBottom: 15}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: w * 0.65}}>
              {/* <p style={{color: '#9be0dd', fontSize: 18, textAlign: 'left', marginTop: 5}} >Brand:</p> */}
              <form style={{marginBottom: 5}} onSubmit={e => { e.preventDefault(); }}>
                <input type="numeric" style={{width: 50, fontSize: 16, textAlign: 'center'}} value={item.quantity} 
                  onChange={(event) => {
                    if (event.target.key === 'Enter') {
                      event.preventDefault();
                    }
                    else {
                      onQuantityChange(item, event.target.value)
                    }
                  }}
                  maxLength="3"
                />
              </form>
              <p style={{color: '#404040', fontSize: 18, textAlign: 'left', marginTop: 5, marginLeft: 5}} >@ ₱{formatNumberWithComma(item.brand.price, 2)}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: w * 0.35, height: 30, marginTop: 0}}>
              <p style={{color: '#404040', fontSize: 18, textAlign: 'right'}} >₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
            </div>
          </div> 
        </>
      </div>
    </div>
  }

  const reorderPressed = () => {
    _loadBoughtHistory();
    _loadAlphaBought();
    setShowReorder(true);
    _onSendSocket('Show', 'Order list', 'showOrderlist', null);
  }

  const _loadBoughtHistory = () => {
    getBoughtHistory({status: 0, userId: user.id})
    .then(({data}) => {
      setList(data.data.map(item => {
        return {
          ...item, isSelected: false
        }
      }))
      // setShowOrderlist(true)
      // setShowAlphalist(false) 
    })
    .catch(e => {
        console.warn('get bought ERROR', e.message);
    });
  }

  const _loadAlphaBought = () => {
    getAlphaBought({status: 0, userId: user.id})
    .then(({data}) => {
      setAlphalist(data.data.map(item => {
        return {
          ...item, isSelected: false
        }
      })) 
      // setShowAlphalist(true)
      // setShowOrderlist(false)
    })
    .catch(e => {
        console.warn('get alpha ERROR', e.message);
    });
  }

  const TabTop = (props) => { 
    const {tabArr,action,longaction} = props
    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'row',width:'100%', borderRadius: 3, 
          backgroundColor: '#55b88a', height: 40, zIndex: 100}}>
            {
              tabArr.map((item,index)=> {
                const indicator = item.isSelect ? <div style={{width: w / tabArr.length * 0.65, height: 3,
                  backgroundColor: '#9be0dd', marginTop: 5, position: 'absolute', marginTop: 37 }} pointerEvents='none'/> : null;
                return <div key={index} style={item.isSelect ? 
                    {alignItems:'center', width: '42%', height: 40, zIndex: 10, top: 5, cursor: 'pointer', display: 'flex', flexDirection: 'column'} : 
                    {alignItems:'center', width: '42%', height: 40, zIndex: 10, top: 5, cursor: 'pointer', display: 'flex', flexDirection: 'column'}
                  } onClick={()=>action(index)}>
                    <p style={{color: '#404040', fontSize: 12,
                      marginTop: 5,
                      color: "white",
                      marginTop: 10,
                      fontWeight:'bold',
                      alignSelf: "center",
                      textAlign: 'center'}} pointerEvents='none'>{item.tabName}</p>
                    {indicator}
                </div>
              })
            }
        </div>
    );
  }

  const tabAction = (index) => {
    console.log(`index=${index}`)
    let arr = [...tabArr]
    arr.map((item, i) =>
        arr[i].isSelect = false
    )
    arr[index].isSelect = true
    setTabIndex(index);
    setTabArr(arr);
    if (index == 0) {
      setShowOrderlist(true)
      setShowAlphalist(false)
      _onSendSocket('Show', 'Order list', 'showOrderlist', null)
    }
    else if (index == 1) {
      setShowOrderlist(false)
      setShowAlphalist(true)
      _onSendSocket('Show', 'Alpha list', 'showAlphalist', null)
    }
  }

  const _onDateChecked = (rowData) => {
    let orderId = rowData.id;
    let toCheck;
    if (rowData.isSelected) {
      toCheck = false;
      _onSendSocket('Uncheck', 'Date', 'uncheckOrderlist', `${orderId}`)
    }
    else {
      toCheck = true;
      _onSendSocket('Check', 'Date', 'checkOrderlist', `${orderId}`)
    }
    const list1 = [...list].map((item) => {
      if (item.id == orderId) {
        const details = [...item.details].map(det => {
          return {
            ...det,
            isSelected: toCheck
          }
        });
        return {
          ...item,
          isSelected: toCheck,
          details: [...details]
        }
      }
      else {
        return item;
      }
    })
    setList(list1)
  }

  const _renderReorderData = (rowData, rowId) => {
    return <div key={rowId} style={{display: "flex", width: w, minHeight: 75, flexDirection: 'column', justifyContent: 'center', 
      alignItems: 'flex-start', cursor: 'pointer', backgroundColor: 'transparent'}} >
        <div style={{flexDirection: 'row', width: w, justifyContent: 'flex-start', alignItems: 'center', backgroundColor: '#ffcaca', height: 80}}>
          <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', paddingTop: 5, marginTop: 20}}>
            <p style={{marginLeft: 15, width: w*0.75, color: '#404040', fontSize: 22, textAlign: 'left', height: 35}} >Date: {Moment(rowData.createDate).format('MMM D, YYYY')}</p>
          </div>
          <div style={{position: 'relative', bottom: 40, left: w - 240}}>
              {/* <CheckBox
                value={rowData.isSelected}
                onValueChange={this._onDateChecked.bind(this, rowData)}
                boxType={'square'}
                onCheckColor={'#9be0dd'}
                onTintColor={'#9be0dd'}
                disabled={false}
                style={{width: scale(20), height: scale(20)}}
              /> */}
            <input type="checkbox" checked={rowData.isSelected} style={{transform: 'scale(2)', marginBottom: 5}} onChange={() => _onDateChecked(rowData)} />
          </div>
        </div>
        {props.callType == 2 && rowData.details.filter(item => item.brand.numGenerics > 1).map((item,index) => _renderReorderSelectedItem(item, index, rowData.id))}
        {props.callType !== 2 && rowData.details.map((item,index) => _renderReorderSelectedItem(item, index, rowData.id))}
    </div>
  }

  const _onItemChecked = (rowData, orderId) => {
    let brandId = rowData.brand.id;
    let toCheck;
    if (rowData.isSelected) {
      toCheck = false;
      _onSendSocket('Uncheck', 'Item', 'uncheckOrderlist', `${orderId},${brandId}`)
    }
    else {
      toCheck = true;
      _onSendSocket('Check', 'Item', 'checkOrderlist', `${orderId},${brandId}`)
    }
    const list1 = list.map((item) => {
      if (item.id == orderId) {
        const details = item.details.map(det => {
          if (det.brand.id == brandId) {
            console.log(`orderId=${orderId}; brandId=${brandId}; det.brand.id=${det.brand.id}; det.brand.name=${det.brand.name}`)
            return {
              ...det,
              isSelected: toCheck
            }
          }
          // console.log(`orderId=${orderId}; brandId=${brandId}; det.brand.id=${det.brand.id}; det.brand.name=${det.brand.name}; det.isSelected=${det.isSelected}`)
          return det
        })
        if (toCheck) {
          return {
            ...item,
            details: [...details]
          }
        }
        else {
          return {
            ...item,
            isSelected: false,
            details: [...details]
          }
        }
      }
      return item;
    })
    setList(list1)
    if (toCheck && props.callType == 2) {
      _onSendSocket('Show', 'Generics', 'showGenerics', `${orderId},${brandId}`)
      _showGenerics(rowData, orderId);
    }
  }

  const _renderReorderSelectedItem = (rowData, rowId, orderId) => {
    // console.log(`_renderReorderSelectedItem=${JSON.stringify(rowData)}`)
    return <div key={`o-${orderId}-${rowId}`} style={{display: "flex", width: w, padding: 5, flexDirection: 'column', justifyContent: 'center', 
        alignItems: 'flex-start', backgroundColor: rowId % 2 == 0 ? 'transparent' : '#40404010', height: 80}} >
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginTop: 30}}>
        <p style={{left: 20, color: '#9be0dd', maxWidth: w*0.75, fontSize: 18, textAlign: 'left', height: 20}} >{rowData.brand.name} {rowData.dosage.name}</p>
        <p style={{fontSize: 18, marginLeft: 25, color: '#404040', textAlign: 'left', height: 20}}>@ ₱{formatNumberWithComma(rowData.brand.price, 2)}</p>
      </div>
      <div style={{position: 'relative', bottom: 50, left: w - 50}}>
        <input type="checkbox" checked={rowData.isSelected} style={{transform: 'scale(2)', marginBottom: 5, borderColor: '#9be0dd'}} onChange={() => _onItemChecked(rowData, orderId)} />
        {/* <CheckBox
          value={rowData.isSelected}
          onValueChange={this._onItemChecked.bind(this, rowData, orderId)}
          boxType={'square'}
          onCheckColor={'#9be0dd'}
          onTintColor={'#9be0dd'}
          disabled={false}
          style={{width: scale(20), height: scale(20)}}
        /> */}
      </div>
    </div>
  }

  const _onItemCheckedAlpha = (rowData) => {
    let brandId = rowData.brand.id;
    let toCheck;
    if (rowData.isSelected) {
      toCheck = false;
      _onSendSocket('Uncheck', 'Item', 'uncheckAlphalist', `${brandId}`)
    }
    else {
      toCheck = true;
      _onSendSocket('Check', 'Item', 'checkAlphalist', `${brandId}`)
    }
    const alphalist1 = alphalist.map((det) => {
      if (det.brand.id == brandId) {
          console.log(`brandId=${brandId}; det.brand.id=${det.brand.id}; det.brand.name=${det.brand.name}`)
          return {
            ...det,
            isSelected: toCheck
          }
        }
        // console.log(`orderId=${orderId}; brandId=${brandId}; det.brand.id=${det.brand.id}; det.brand.name=${det.brand.name}; det.isSelected=${det.isSelected}`)
        return det
    })
    setAlphalist(alphalist1);
    if (toCheck && props.callType == 2) {
      _onSendSocket('Show', 'Generics', 'showGenerics', `${brandId}`)
      _showGenerics(rowData);
    }
  }

  const _renderReorderSelectedItemAlpha = (rowData, rowId) => {
    // console.log(`_renderReorderSelectedItemAlpha=${JSON.stringify(rowData)}`)
    return <div key={`a-${rowId}`} style={{display: "flex", width: w, padding: 5, flexDirection: 'column', justifyContent: 'center', 
      alignItems: 'flex-start', backgroundColor: rowId % 2 == 0 ? 'transparent' : '#40404010', minHeight: 80}} disabled={props.callType != 2}
      onClick={() => {
        if (props.callType == 2) {
          _onSendSocket('Show', 'Generics', 'showGenerics', `${rowData.brand.id}`)
          _showGenerics(rowData);
        }
      }}
    >
      
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginTop: 30}}>
        <p style={{left: 20, color: '#9be0dd', maxWidth: w*0.75, fontSize: 18, textAlign: 'left', minHeight: 20}} >{rowData.brand.name} {rowData.dosage.name} ({rowData.generic.name})</p>
        <p style={{fontSize: 18, marginLeft: 25, color: '#404040', textAlign: 'left', height: 20}}>@ ₱{formatNumberWithComma(rowData.brand.price, 2)}</p>
      </div>
      <div style={{position: 'relative', bottom: 50, left: w - 50}}>
        <input type="checkbox" checked={rowData.isSelected} style={{transform: 'scale(2)', marginBottom: 5, borderColor: '#9be0dd'}} onChange={() => _onItemCheckedAlpha(rowData)} />
        {/* <CheckBox
          value={rowData.isSelected}
          onValueChange={this._onItemCheckedAlpha.bind(this, rowData)}
          boxType={'square'}
          onCheckColor={'#9be0dd'}
          onTintColor={'#9be0dd'}
          disabled={false}
          style={{width: scale(20), height: scale(20)}}
        /> */}
      </div>
    </div>
  }

  const _onSaveReorder = () => {
    let skus;
    console.log(`list.map(item => item.details)=${JSON.stringify(list.map(item => item.details).flat().filter(item => item.isSelected))}`)
    if (tabIndex == 0) {
      skus = list.map(item => item.details).flat().filter(item => item.isSelected)
    }
    else {
      skus = alphalist.filter(item => item.isSelected).map(item => {
        return {
          ...item,
          quantity: 1
        }
      })
    }
    console.log(`this.state.user=${JSON.stringify(user)}; skus=${JSON.stringify(skus)}`)
    updateCart({
      userId: user.id,
      details: skus.map((item, index) => {
        return {
          brandedId: item.brand.id,
          quantity: item.quantity,
          sortOrder: index
        }
      }),
      eta: new Date(date).toISOString().slice(0,-5)
    })
    .then(({data}) => {
      _getCart(user.id);
      setEnableSave(false);
      setShowReorder(false);
      setShowNewCart(true);    
      setAddedItem(true);
      setShowMayWe(false);
    })
    .catch(e => {
      console.warn('update cart ERROR', e.message);
    });
  }

  const _showGenerics = (rowData, rowId) => {

  }

  const randomString = () => {
    const length = 4;
    const chars = "123456789ABCDEFGHIJKLMNPQRSTUVWXYZ";
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  const checkPhone = async (phoneNumber) => {
    const response = await checkPhoneNumber({phoneNumber: encodeURI(phoneNumber)});
    if (response) {
      if (response.data.data) {
        console.log(`4. checkPhone.response=${JSON.stringify(response.data.data)}`)
        setUser(response.data.data)
      }
    }
  }

  const register = async (code) => {
    try {
      const param = {
        firstname: user.firstname,
        lastname: user.lastname,
        displayName: `${user.firstname} ${user.lastname}`,
        phoneNumber: `+${code}`,
        address: code,
        birthDate: `1985-01-01`,
        countryCode: 'PH',
        ccAgentId: props.userData.userId
      }
      console.log(`param=${JSON.stringify(param)}`)
      const response = await registerRx(param);
      console.log(`register.response=${JSON.stringify(response)}`)
      if (response) {
        // console.log(`there`)
        checkPhone(`+${code}`);
      }    
    }
    catch (e) {
      console.log(e);
    }
  }

  const _addMember = () => {
    setShowSearchMember(false);
    setShowAddMember(true);
  }

  const _onUpButtonPress = (userId) => {
    _onSendSocket("Scroll", "Up", "scrollUp", null);
  }

  const _onDownButtonPress = (userId) => {
    _onSendSocket("Scroll", "Down", "scrollDown", null);
  }

  const _onShowButtonPress = (itemId) => {
    _onSendSocket("Show", "Image", "showImage", itemId);
  }

  const _onHideButtonPress = (userId) => {
    _onSendSocket("Hide", "Image", "hideImage", null);
  }

  return (
    <div className={classes.getOrderContainer}>
      <div className={classes.getOrderContainer}>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', border: 'solid 5px #9be0dd',
            marginTop: 50, height: 800 }}>
            <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 400, maxWidth: 400, height: 800, border: 'solid 2px #9be0dd' }}>
              <>
              <div style={{ display: "flex", backgroundColor: 'transparent', marginTop: 3, marginLeft: 2, alignItems: 'center', flexDirection: 'column', justifyContent: 'flex-start', flexDirection: 'column' }}>
                <div style={{ display: "flex", backgroundColor: 'transparent', height: w, alignItems: 'center', flexDirection: 'column', justifyContent: 'flex-start' }}>
                  {typeof sessionId !== 'undefined' && typeof token !== 'undefined' &&
                    <OTSession apiKey={apiKey} sessionId={sessionId} token={token} audioTrack={true} publishAudio={true} publishVideo={true} videoTrack={true}>
                      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: w, width: w - 2, marginRight: 2, marginTop: -1, top: 0,
                        borderTopLeftRadius: 0, borderTopRightRadius: 0, overflow: 'hidden', backgroundColor: 'gray'}}
                      >
                        <OTStreams>
                          <OTSubscriber  properties={{ insertMode: "append", width: w-2, height: w }}/>
                        </OTStreams>
                      </div>
                      <div style={{display: 'flex', width: 125, height: 125, justifyContent: 'center', alignItems: 'center', position: 'relative', paddingTop: 0,  bottom: 400, marginLeft: 1, borderTopRightRadius: 0, overflow: 'hidden'}}>
                        <OTPublisher properties={{
                            publishAudio: isAudioEnabled,
                            publishVideo: isVideoEnabled,
                            width: 125, height: 125,
                          }}
                        />
                      </div>
                    </OTSession>
                  }
                </div>
                <div style={{flexDirection: 'column', width: w, height: h - 40, backgroundColor: 'transparent', justifyContent: 'flex-start', 
                  alignItems: 'center', borderRadius: 20, marginTop: 0}} 
                >
                  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', minHeight: w * 0.85, width: w, top: 0,
                    borderTopLeftRadius: 0, borderTopRightRadius: 0, overflow: 'hidden'}}
                  >
                    {/* {showText  && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#9be0dd', fontSize: 18, position: 'absolute', bottom: h * 0.38}}>{textToShow}</p>}
                    {showText  && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 22, position: 'absolute', bottom: h * 0.30}}>{subTextToShow}</p>} */}
                    {showText && <div style={{marginTop: 0, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', bottom: h * 1.04}}>
                        <div style={{position: 'absolute', marginTop: h * 0.20, display: 'flex', flexDirection: 'column', alignItems: 'center', height: h * 0.83, overflowY: 'auto', overflowX: 'hidden'}}>
                          {showText  && <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.94, color: '#dc2828', fontSize: 18, marginTop: h * 0.35}}>{textToShow}</p>}
                          {showText  && <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.94, color: '#404040', fontSize: 22, marginTop: h * 0.02}}>{subTextToShow}</p>}
                          {showText  && imageToShow !== "" && imageToShow && <img src={imageToShow} style={{width: w * 0.6, height: w * 0.6, marginTop: h * 0.16}}/>}
                        </div>
                      </div>
                    }
                    {showMayWe && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 22, position: 'absolute', bottom: h * 0.38}}>May we take</p>}
                    {showMayWe && !showText && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 22, position: 'absolute', bottom: h * 0.30}}>your order please?</p>}
                    {(showIsReseta || showIsOrderlist) && <div style={{position: 'absolute', bottom: h * 0.16, width: w * 0.55, maxHeight: w * 0.5, overflowX: 'hidden', overflowY: 'scroll'}}>
                        {showIsReseta && resetaImage && !showNewCart && <img src={resetaImage} style={{width: w * 0.5, height: w * 0.5, }} />}
                        {showIsOrderlist && orderlistImage && !showNewCart && <img src={orderlistImage} style={{width: w * 0.5, height: w * 0.5}} />}    
                      </div>                    
                    }
                    {/* {showMayWe && !showText && showCapture && <div style={{position: 'absolute', bottom: h * 0.05, left: 38, width: 40, height: 40, cursor: 'pointer'}} > */}
                    {showMayWe && !showText && showCapture && <div style={{position: 'absolute', bottom: h * 0.05, width: 100, height: 100, cursor: 'pointer'}} >
                        <img src={Prescription} style={{width: 100, height: 100}} />
                      </div>
                    }
                    {/* {(showMayWe && showCapture) && <p style={{textAlign: 'center', fontWeight: '500', color: '#404040', fontSize: 8, position: 'absolute', bottom: 0, left: 33, width: 40, textAlign: 'center'}}>Prescription</p>} */}
                    {(showMayWe && showCapture) && <p style={{textAlign: 'center', fontWeight: '500', color: '#404040', fontSize: 12, position: 'absolute', bottom: 0, width: 100, textAlign: 'center'}}>Prescription</p>}
                    {/* {showMayWe && !showText && showCapture && <div style={{position: 'absolute', bottom: h * 0.05, left: w * 0.9 - 38, width: 40, height: 40, cursor: 'pointer'}} >
                        <img src={Orderlist} style={{width: 40, height: 40}} />
                      </div>
                    } */}
                    {/* {(showMayWe && showCapture) && <p style={{textAlign: 'center', fontWeight: '500', color: '#404040', fontSize: 8, position: 'absolute', bottom: 0, left: w * 0.9 - 45, width: 50, textAlign: 'center'}}>Order List</p>} */}
                    {/* {showDownload && <a style={{position: 'absolute', bottom: h * 0.46, width: w, textAlign: 'center', fontWeight: '500', color: '#99cffd', textDecoration: 'underline', textDecorationColor: '#99cffd', fontSize: 22, cursor: 'pointer'}}>Download the RxClub App</a>} */}
                    {showDownload && <p style={{position: 'absolute', bottom: h * 0.46, width: w, textAlign: 'center', fontWeight: '500', color: '#404040', fontSize: 22, cursor: 'pointer'}}>Thank you for using</p>}
                    {showDownload && <p style={{position: 'absolute', bottom: h * 0.38, width: w, textAlign: 'center', fontWeight: '500', color: '#404040', fontSize: 22, cursor: 'pointer'}}>{ids.appName}.</p>}
                    {showDownload && !showText && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 22, position: 'absolute', bottom: h * 0.30}}>Wait for the text or call of</p>}
                    {showDownload && !showText && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 24, position: 'absolute', bottom: h * 0.22}}>the Rider regarding your delivery.</p>}
                    {showDownload2 && !showText && <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 22, position: 'absolute', bottom: h * 0.60}}>STEPS:</p>}
                    {showDownload2 && !showText && <div style={{display: 'flex', flexDirection: 'row', position: 'absolute', bottom: h * 0.47, width: w * 0.8}}>
                        <p style={{textAlign: 'left', fontWeight: '500', width: 25, color: '#404040', fontSize: 22}}>1.</p>
                        <a style={{textAlign: 'left', fontWeight: '500', color: '#99cffd', textDecoration: 'underline', textDecorationColor: '#99cffd', fontSize: 22, cursor: 'pointer'}}>Download app</a>
                      </div>
                    }
                    {showDownload2 && !showText && <p style={{textAlign: 'left', fontWeight: '500', width: w * 0.8, color: '#404040', fontSize: 22, position: 'absolute', bottom: h * 0.40}}>2. Input phone number</p>}
                    {showDownload2 && !showText && <p style={{textAlign: 'left', fontWeight: '500', width: w * 0.65, color: '#404040', fontSize: 22, position: 'absolute', bottom: h * 0.34}}>and OTP</p>}
                    {showDownload2 && !showText && <p style={{textAlign: 'left', fontWeight: '500', width: w * 0.8, color: '#404040', fontSize: 22, position: 'absolute', bottom: h * 0.27}}>3. Get additional Savings</p>}
                    {showDownload2 && !showText && <div style={{position: 'absolute', bottom: h * 0.20, display: 'flex', flexDirection: 'row', width: w * 0.8, alignItems: 'center'}}>
                        <p style={{textAlign: 'left', fontWeight: '500', color: '#404040', fontSize: 18, fontStyle: 'italic'}}>Tap Delivery icon</p>
                        <img src={RiderWith1} style={{width: 36, height: 36, marginBottom: 12, marginLeft: 5}}/>
                      </div>
                    }
                    {!showText && showNewCart && !showFinalCost && !showDiscount && !showUserDetails && <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                          {selectedSkus.length > 0 && <div
                              style={{width: w - 2, height: h * 0.8, overflow: 'auto', backgroundColor: 'transparent', borderRadius: 20, justifyContent: 'flex-start', alignItems: 'center', overflowX: 'hidden'}}
                            >
                              <div style={{display: "flex", width: w * 0.95, alignItems: 'center', justifyContent: 'center', flexDirection: 'column', overflowX: 'hidden'}}>
                                <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.05, overflowX: 'hidden'}}>
                                    <div style={{minHeight: 20}}>
                                      { selectedSkus.map((item,index) => renderItem(item, index)) }
                                    </div>
                                    <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w * 0.9, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                                      <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 28, marginTop: 20, height: 20}} >Total Price:</p>
                                      <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 50}} >₱{formatNumberWithComma(selectedSkus.reduce((a,v) =>  a = a + v.quantity * v.brand.price, 0 ),2)}</p>
                                    </div>
                                </div> 
                              </div>   
                            </div> 
                          }
                        </div>      
                      </div>
                    }
                    {!showText && showUserDetails && !showNewCart && !showFinalCost && !showDiscount && <div style={{marginTop: 0, marginLeft: 20, display: 'flex', 
                        flexDirection: 'column', alignItems: 'center', backgroundColor: 'transparent'}}
                      >
                        <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center', height: h * 0.83, overflow: 'auto'}} >
                          <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
                            <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>First Name:</p>
                            <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{user.firstname}</p>
                          </div>
                          <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                            <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Last Name:</p>
                            <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{user.lastname}</p>
                          </div>
                          <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                            <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Phone #:</p>
                            <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{user.phoneNumber}</p>
                          </div>
                          {user.address && user.address !== "" && <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                              <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Address:</p>
                              <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{user.address}</p>
                            </div>
                          }
                          { user.scPwdId &&
                            <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                              {/* <p style={{fontSize: 16, color: '#9be0dd', width: '40%', textAlign: 'left'}}>SC/PWD ID#:</p> */}
                              {/* <p style={{fontSize: 16, color: '#9be0dd', width: '40%', textAlign: 'left'}}>{value ? value : user.scId !== null ? 'SC' : 'PWD'} ID#:</p> */}
                              <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>{value} ID#:</p>
                              <p style={{fontSize: 20, color: '#404040', width: '45%', marginLeft: 5, textAlign: 'left'}}>{user.scPwdId}</p>
                              <div style={{width: '15%', marginBottom: 10}}>
                                <img src={idOn ? ID : IDOFF} style={{width: 40, height: 40}} />
                              </div>
                            </div>
                          }
                          { user.scPwdIdUrl &&
                            <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20}}>
                              <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}> </p>
                              <img src={user.scPwdIdUrl} style={{width: 200, height: 200}} />
                            </div>
                          }
                          {typeof user.Referror !== 'undefined' && user.Referror &&
                            <div style={{width: w * 0.8, left: w * 0.1, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                              <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Referror:</p>
                              <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{user.Referror.firstname} {user.Referror.lastname}</p>
                            </div>
                          }
                          { user.tipidCardId &&
                            <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                              <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Tipid Card #:</p>
                              <p style={{fontSize: 20, color: '#404040', width: '45%', marginLeft: 5, textAlign: 'left'}}>{user.tipidCardId}</p>
                              <div style={{width: '15%', marginBottom: 10}}>
                                <img src={tipidIdOn ? TIPID_ID : TIPID_IDOFF} style={{width: 40, height: 40}} />
                              </div>
                            </div>
                          }
                          { user.tipidCardIdUrl &&
                            <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20}}>
                              <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}> </p>
                              <img src={user.tipidCardIdUrl} style={{width: 200, height: 200}} />
                            </div>
                          }
                        </div>
                      </div>
                    }
                    {!showText && showDiscount && <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center', height: h * 0.75, overflowY: 'auto', overflowX: 'none'}} >
                          <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.025}}>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                              <p style={{left: 10, color: '#404040', fontSize: 18, width: '65%', textAlign: 'left', fontWeight: '600'}} >TOTAL PRICE:</p>
                              <p style={{fontWeight: '600', fontSize: 20, width: '30%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice,2)}</p>
                            </div>
                            <div style={{marginTop: 40}}>
                              {discountTypesData.map((item, index) => {
                                return <>
                                { item.name.toUpperCase().includes('SENIOR') &&
                                  <div style={{height: 20}} />
                                }
                                <div key={index} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 35}}>
                                  { item.id == ids.discountTypeTipidCard && <p style={{left: 10, color: '#404040', fontSize: 14, width: '65%', textAlign: 'left', fontWeight: '500'}} >{index+1}. {item.name.toUpperCase()} ({formatNumberWithComma(item.amount,0)}%):</p> }
                                  { item.is_percentage == 1 && item.id != ids.discountTypeTipidCard && <p style={{left: 10, color: '#404040', fontSize: 14, width: '65%', textAlign: 'left', fontWeight: '500'}} >{index+1}. {item.name.toUpperCase()} ({formatNumberWithComma(item.amount,0)}%):</p> }                    
                                  { item.is_percentage == 0 && item.id != ids.discountTypeTipidCard && <p style={{left: 10, color: '#404040', fontSize: 14, width: '65%', textAlign: 'left', fontWeight: '500'}} >{index+1}. {item.name.toUpperCase()}:</p> }                    
                                  <p style={{fontWeight: '500', fontSize: 18, width: '30%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.discountValue,2)}</p>
                                </div>
                                {/* { index < discountTypesData.length - 1 &&
                                  <div key={`st${index}`} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                                    <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >Sub-Total:</p>
                                    <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.subTotal,2)}</p>
                                  </div>
                                } */}
                                { item.name.toUpperCase().includes('REFERRAL') &&
                                  <div style={{height: 20}} />
                                }
                                </>
                              })}
                              <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w * 0.95, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                                <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 28, marginTop: 20, height: 20}} >TOTAL DISCOUNTS:</p>
                                <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#9be0dd', fontSize: 50}} >₱{formatNumberWithComma(totalDiscount,2)}</p>
                              </div>
                            </div>
                          </div> 
                        </div>
                      </div>
                    }
                    {!showSms && !showText && showFinalCost && <div style={{marginTop: 0, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{textDecoration: 'none', flexDirection: 'column', width: w - 5, justifyContent: 'center', alignItems: 'center', height: h * 0.83, overflowX: 'hidden', overflowY: 'auto'}} >
                          <div style={{display: "flex", width: w * 0.9, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                            <div style={{marginTop: 20, width: w * 0.9, alignItems: 'center', marginLeft: w * 0.05}}>
                                { selectedDs.details.map((item,index) => renderItem(item, index)) }
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.9, height: 30}}>
                                  <p style={{left: 10, color: '#404040', fontSize: 17, width: '65%', textAlign: 'left', fontWeight: '500'}} >TOTAL PRICE:</p>
                                  <p style={{fontWeight: '500', fontSize: 20, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice,2)}</p>
                                </div>
                                {totalDiscount > 0 && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.9, height: 25}}>
                                    <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >TOTAL DISCOUNTS:</p>
                                    <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, fontStyle: 'italic', color: '#9be0dd', textAlign: 'right'}}>₱{formatNumberWithComma(-totalDiscount,2)}</p>
                                  </div>
                                }
                                {totalDiscount > 0 && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.9, height: 25, marginBottom: 10}}>
                                    <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >NET AMOUNT:</p>
                                    <p style={{fontWeight: '500', fontSize: 20, width: '35%', marginRight: 5, color: '#55b88a', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice-totalDiscount,2)}</p>
                                  </div>
                                }
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.9, height: 25}}>
                                  <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >DELIVERY FEE ({selectedDeliveryType.name}):</p>
                                  <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(deliveryFee,2)}</p>
                                </div>
                                <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w * 0.9, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                                  <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 28, marginTop: 20, height: 20}} >Pay only:</p>
                                  <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 50}} >₱{formatNumberWithComma((totalPrice - totalDiscount) + Number(deliveryFee),2)}</p>
                                  <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 20, marginTop: 30}} >{selectedDs.name}</p>
                                </div>
                            </div> 
                          </div>  
                        </div>
                      </div>
                    }
                    {!showSms && !showText && showConfirm && alreadyBought && !showFinalCost && <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{textDecoration: 'none', flexDirection: 'column', width: w - 5, justifyContent: 'center', alignItems: 'center', height: h * 0.8, overflow: 'auto',}} >
                          <div style={{marginTop: 20, width: w * 0.9, alignItems: 'center', marginLeft: w * 0.05, marginBottom: 30}}>
                            <div style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 40, height: 30}}>You saved</p>
                              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: 'black', fontSize: 54, height: 54}}>₱{formatNumberWithComma(totalDiscount,2)}</p>
                              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 18}}>in your order</p>
                            </div> 
                            <div style={{flexDirection: 'column', alignItems: 'flex-start', marginTop: 60}}>
                              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 18, height: 14}}>Your Total Savings in {ids.appName}:</p>
                              <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 28, height: 28}}>₱{formatNumberWithComma(totalDiscount,2)}</p>
                            </div> 
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: 60, width: w * 0.8, marginLeft: w * 0.1}}>
                              <p style={{textAlign: 'left', fontWeight: '500', color: '#dc2828', fontSize: 16, height: 24, width: w * 0.25}}>Name: </p>
                              <p style={{textAlign: 'left', fontWeight: '500', color: '#404040', fontSize: 16, marginLeft: 5, width: w * 0.55}}>{user.firstname} {user.lastname}</p>
                            </div> 
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: w * 0.8, marginLeft: w * 0.1}}>
                              <p style={{textAlign: 'left', fontWeight: '500', color: '#dc2828', fontSize: 16, height: 24, width: w * 0.25}}>Address:</p>
                              <p style={{textAlign: 'left', fontWeight: '500', color: '#404040', fontSize: 16, marginLeft: 5, width: w * 0.55}}>{user.address}</p>
                            </div>
                            {typeof user.Referror !== 'undefined' && user.Referror &&
                              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: w * 0.8, marginLeft: w * 0.1}}>
                                <p style={{textAlign: 'left', fontWeight: '500', color: '#dc2828', fontSize: 16, height: 24, width: w * 0.25}}>Referror:</p>
                                <p style={{textAlign: 'left', fontWeight: '500', color: '#404040', fontSize: 16, marginLeft: 5, width: w * 0.55}}>{user.Referror.firstname} {user.Referror.lastname}</p>
                              </div>
                            }
                          </div> 
                        </div>
                      </div>
                    }
                    {showSms && <>
                        <div style={{width: w * 0.95, minHeight: height * 0.4, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                          <div style={{flexDirection: 'column', alignItems: 'flex-start', marginTop: 60}}>
                            <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 18, marginTop: 100}}>Please wait for the</p>
                            <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 18}}>SMS link</p>
                            <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 18}}>to track your order.</p>
                          </div> 
                          <div style={{flexDirection: 'column', alignItems: 'flex-start', marginTop: 20}}>
                            <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 24, marginBottom: 10}}>Thank you!</p>
                          </div> 
                        </div> 
                      </>
                    }
                    {showThankYou && <>
                        <div style={{width: w * 0.95, minHeight: height * 0.4, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                          <div style={{flexDirection: 'column', alignItems: 'flex-start', marginTop: 55}}>
                            <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 20, marginTop: 140}}>Thank you</p>
                            <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 20}}>for downloading</p>
                            <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 20}}>RxClub App</p>
                          </div> 
                        </div> 
                      </>
                    }
                  </div>
                  {showProductImage && <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: w + h - 78, width: w - 2, bottom: 4,
                      borderTopLeftRadius: 0, borderTopRightRadius: 0, overflow: 'hidden', position: 'absolute', backgroundColor: 'white'}}
                    >
                      <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.94, color: '#404040', fontSize: 18, marginTop: h * 0.35}}>{textToShow}</p>
                      <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.94, color: '#404040', fontSize: 22, marginTop: h * 0.02}}>{subTextToShow}</p>
                      <img src={imageToShow} style={{width: w * 0.85, height: w * 0.85, marginTop: h * 0.16}}/>
                    </div>
                  }
                  {finalized && !showProductImage && <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: w + h - 78, width: w - 2, bottom: 4,
                      overflowX: 'hidden', overflowY: 'auto', position: 'absolute', backgroundColor: 'white'}}
                    >
                      <div style={{height, width, alignItems: 'center', flexDirection: 'column', display: 'flex', backgroundColor: 'white'}}>
                        <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                          <div style={{display: 'flex', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center', marginTop: 40}}>
                            <img src={Logo} style={{width: 84, height: 84}} />
                            <p style={{fontSize: 20, fontWeight: '700', color: '#404040'}}>{ids.appName}</p>
                            <div style={{height: 1, width: w * 0.95, backgroundColor: '#404040', marginTop: 10, marginBottom: 10}}></div>
                          </div>
                          {selectedSkus.length > 0 && <div
                              style={{width: w, minHeight: h * 0.8, backgroundColor: 'transparent', borderRadius: 20, justifyContent: 'flex-start', alignItems: 'center'}}
                            >
                              <div style={{display: "flex", width: w * 0.95, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                                <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.1}}>
                                    <div style={{minHeight: 20}}>
                                      { selectedSkus.map((item,index) => renderItem(item, index)) }
                                    </div>
                                    <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w * 0.95, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                                      <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 28, marginTop: 20, height: 20}} >Total Price:</p>
                                      <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 50}} >₱{formatNumberWithComma(selectedSkus.reduce((a,v) =>  a = a + v.quantity * v.brand.price, 0 ),2)}</p>
                                    </div>
                                </div> 
                              </div>   
                            </div> 
                          }
                        </div> 
                      </div>
                    </div>
                  }
                  { showUserDetails && <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: w + h - 78, width: w - 2, bottom: 4,
                      overflowX: 'hidden', overflowY: 'auto', position: 'absolute', backgroundColor: 'white'}}
                    >
                      <div style={{height, width, alignItems: 'center', flexDirection: 'column', display: 'flex', backgroundColor: 'white'}}>
                        <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                          <div style={{display: 'flex', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center', marginTop: 40}}>
                            <img src={Logo} style={{width: 84, height: 84}} />
                            <p style={{fontSize: 20, fontWeight: '700', color: '#404040'}}>{ids.appName}</p>
                            <div style={{height: 1, width: w * 0.95, backgroundColor: '#404040', marginTop: 10, marginBottom: 10}}></div>
                          </div>
                          <div style={{textDecoration: 'none', flexDirection: 'column', width: w * 0.9, marginLeft: w * 0.05, justifyContent: 'center', alignItems: 'center'}} >
                            <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
                              <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>First Name:</p>
                              <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{user.firstname}</p>
                            </div>
                            <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                              <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Last Name:</p>
                              <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{user.lastname}</p>
                            </div>
                            <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                              <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Phone #:</p>
                              <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{user.phoneNumber}</p>
                            </div>
                            {user.address && user.address !== "" && <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                                <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Address:</p>
                                <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{user.address}</p>
                              </div>
                            }
                            { user.scPwdId &&
                              <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>{value} ID#:</p>
                                <p style={{fontSize: 20, color: '#404040', width: '45%', marginLeft: 5, textAlign: 'left'}}>{user.scPwdId}</p>
                                <div style={{width: '15%', marginBottom: 6, opacity: 0}}>
                                  <img src={idOn ? ID : IDOFF} style={{width: 48, height: 48}} />
                                </div>
                              </div>
                            }
                            { (user.scPwdIdUrl) && 
                              <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                <img src={user.scPwdIdUrl} style={{width: w * 0.5, height: w * 0.5, marginLeft: w * 0.1, marginBottom: 20}} />
                              </div>
                            }
                            {typeof user.Referror !== 'undefined' && user.Referror &&
                              <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Referror:</p>
                                <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5, textAlign: 'left'}}>{user.Referror.firstname} {user.Referror.lastname}</p>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  { showDiscount && <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: w + h - 78, width: w - 2, bottom: 4,
                      overflowX: 'hidden', overflowY: 'auto', position: 'absolute', backgroundColor: 'white'}}
                    >
                      <div style={{height, width, alignItems: 'center', flexDirection: 'column', display: 'flex', backgroundColor: 'white'}}>
                        <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                          <div style={{display: 'flex', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center', marginTop: 40}}>
                            <img src={Logo} style={{width: 84, height: 84}} />
                            <p style={{fontSize: 20, fontWeight: '700', color: '#404040'}}>{ids.appName}</p>
                            <div style={{height: 1, width: w * 0.95, backgroundColor: '#404040', marginTop: 10, marginBottom: 10}}></div>
                          </div>
                          <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                            <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.025}}>
                              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                                <p style={{left: 10, color: '#404040', fontSize: 18, width: '65%', textAlign: 'left', fontWeight: '600'}} >TOTAL PRICE:</p>
                                <p style={{fontWeight: '600', fontSize: 20, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice,2)}</p>
                              </div>
                              <div style={{marginTop: 40}}>
                                {discountTypesData.map((item, index) => {
                                  return <>
                                  { item.name.toUpperCase().includes('SENIOR') &&
                                    <div style={{height: 20}} />
                                  }
                                  <div key={index} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 35}}>
                                    { item.id == ids.discountTypeTipidCard && <p style={{left: 10, color: '#404040', fontSize: 14, width: '70%', textAlign: 'left', fontWeight: '500'}} >{index+1}. {item.name.toUpperCase()} ({formatNumberWithComma(item.amount,0)}%):</p> }
                                    { item.is_percentage == 1 && item.id != ids.discountTypeTipidCard && <p style={{left: 10, color: '#404040', fontSize: 14, width: '70%', textAlign: 'left', fontWeight: '500'}} >{index+1}. {item.name.toUpperCase()} ({formatNumberWithComma(item.amount,0)}%):</p> }                    
                                    { item.is_percentage == 0 && item.id != ids.discountTypeTipidCard && <p style={{left: 10, color: '#404040', fontSize: 14, width: '70%', textAlign: 'left', fontWeight: '500'}} >{index+1}. {item.name.toUpperCase()}:</p> }                    
                                    <p style={{fontWeight: '500', fontSize: 18, width: '30%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.discountValue,2)}</p>
                                  </div>
                                  {/* { index < discountTypesData.length - 1 &&
                                    <div key={`st${index}`} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                                      <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >Sub-Total:</p>
                                      <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.subTotal,2)}</p>
                                    </div>
                                  } */}
                                  { item.name.toUpperCase().includes('REFERRAL') &&
                                    <div style={{height: 20}} />
                                  }
                                  </>
                                })}
                                <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w * 0.95, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                                  <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 28, marginTop: 20, height: 20}} >TOTAL DISCOUNTS:</p>
                                  <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#0a9830', fontSize: 50}} >₱{formatNumberWithComma(totalDiscount,2)}</p>
                                </div>
                              </div>
                            </div> 
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  { showFinalCost && <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: w + h - 78, width: w - 2, bottom: 4,
                      overflowX: 'hidden', overflowY: 'auto', position: 'absolute', backgroundColor: 'white'}}
                    >
                      <div style={{height, width, alignItems: 'center', flexDirection: 'column', display: 'flex', backgroundColor: 'white'}}>
                        <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                          <div style={{display: 'flex', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center', marginTop: 40}}>
                            <img src={Logo} style={{width: 84, height: 84}} />
                            <p style={{fontSize: 20, fontWeight: '700', color: '#404040'}}>{ids.appName}</p>
                            <p style={{fontSize: 16, fontWeight: '700', color: '#404040', height: 12}}>DELIVERY RECEIPT</p>
                            <div style={{display: 'flex', flexDirection: 'row', height: 12}}>
                              <p style={{fontSize: 16, fontWeight: '600', color: '#404040'}}>No.:</p>
                              <p style={{fontSize: 16, fontWeight: '600', color: '#9be0dd', marginLeft: 5}}>0000000123</p>
                            </div>
                            <div style={{height: 1, width: w, backgroundColor: '#404040', marginTop: 25, marginBottom: 15}}></div>
                            <p style={{fontSize: 14, color: '#404040', width: w * 0.9, textAlign: 'left', height: 20}}>Name: {user.firstname} {user.lastname}</p>
                            <p style={{fontSize: 14, color: '#404040', width: w * 0.9, textAlign: 'left', height: 20}}>Address: {user.address}</p>                  
                            <p style={{fontSize: 14, color: '#404040', width: w * 0.9, textAlign: 'left', height: 20}}>Contact No.: {user.phoneNumber}</p>                  
                            <p style={{fontSize: 14, color: '#404040', width: w * 0.9, textAlign: 'left', height: 20}}>Delivered by: RxRider</p>                  
                            <div style={{height: 1, width: w, backgroundColor: '#404040', marginTop: 0, marginBottom: 5}}></div>
                          </div>
                          <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                            <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.05}}>
                              { selectedDs.details.map((item,index) => renderItem(item, index)) }
                              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.91, height: 30}}>
                                <p style={{left: 10, color: '#404040', fontSize: 17, width: '65%', textAlign: 'left', fontWeight: '500'}} >TOTAL PRICE:</p>
                                <p style={{fontWeight: '500', fontSize: 20, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice,2)}</p>
                              </div>
                              {totalDiscount > 0 && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.91, height: 30}}>
                                  <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >- TOTAL DISCOUNTS:</p>
                                  <p style={{fontWeight: '500', fontSize: 20, width: '35%', marginRight: 5, color: '#0a9830', textAlign: 'right'}}>₱{formatNumberWithComma(totalDiscount,2)}</p>
                                </div>
                              }
                              {totalDiscount > 0 && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.91, height: 30, marginBottom: 10}}>
                                  <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >NET AMOUNT:</p>
                                  <p style={{fontWeight: '500', fontSize: 20, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice-totalDiscount,2)}</p>
                                </div>
                              }
                              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.91, height: 30}}>
                                <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >DELIVERY FEE ({selectedDeliveryType.name}):</p>
                                <p style={{fontWeight: '500', fontSize: 20, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(deliveryFee,2)}</p>
                              </div>
                              <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w * 0.9, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                                <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 28, marginTop: 20, height: 20}} >Pay only:</p>
                                <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 50}} >₱{formatNumberWithComma((totalPrice - totalDiscount) + Number(deliveryFee),2)}</p>
                                <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 14, marginTop: 30, height: 15}} >{Moment(new Date()).format("MMM DD, YYYY H:mmA")}</p>
                                <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 14, marginTop: 0}} >Drugstore: {selectedDs.name}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
              </>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', 
              justifyContent: 'flex-start', minWidth: 1100, maxWidth: 1100, marginTop: 0, height: 800, 
              border: 'solid 2px #9be0dd' }}
            >
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', 
                justifyContent: 'center', minWidth: 1100, maxWidth: 1100, marginTop: 0, height: 150, backgroundColor: 'transparent' }}
              >
                <div style={{height: 180, width: w * 0.85, justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent', marginTop: 60, marginLeft: 30, marginRight: 20}}>
                  <div
                    style={{marginTop: 0, cursor: 'pointer', display: 'flex', width: w * 0.85, height: 130, 
                      backgroundColor: '#dc2828', borderRadius: 20, justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                    }}
                  >
                    <div style={{display: 'flex', flexDirection: 'row', width: w * 0.85, justifyContent: 'flex-start', alignItems: 'center', height: 60, marginTop: 0}}>
                      {user.avatarBig ? <img src={user.avatarBig} style={{width: 60, height: 60, marginLeft: 15}}/> : <div style={{width: 60, height: 60, marginLeft: 10}}/>}
                      <div style={{width: w * 0.55, height: 80, justifyContent: 'center', marginLeft: 15, backgroundColor: 'transparent'}}>
                        <p style={{textAlign: 'left', fontWeight: '500', color: 'white', fontSize: 14, width: w * 0.55, height: 15}} >{user.firstname} {user.lastname}</p>
                        <p style={{textAlign: 'left', fontWeight: '500', color: 'white', fontSize: 12, width: w * 0.55, height: 40, marginTop: 3}} >{user.address}</p>
                      </div>
                      <div style={{position: 'relative', marginLeft: -20, marginTop: -60}} onClick={() => {
                        setShowSearchMember(true)
                        setIsSearchReferror(false)
                        if (Number(process.env.REACT_APP_IS_MD_CLUB) == 1) {
                          setSelectedScriptIndex(19);
                          setSelectedStepIndex(23);
                        }
                      }}>
                        <img src={rxSearch} style={{width: 30, height: 30}}/>
                      </div>
                    </div>
                  </div>
                </div>
                {controlType == 0 && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', 
                      justifyContent: 'flex-start', width: 700, marginTop: 10
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', 
                        justifyContent: 'center', width: 700, marginTop: 5, height: 65, overflowAnchor: 'auto' 
                      }}
                    >
                      <button
                        onClick={() => { 
                          setControlType(1)
                          setSelectedScriptIndex(-1)
                          setSelectedStepIndex(2)
                        }}
                        style={{
                          padding: "0.5rem 0.75rem", width: 130,
                          color: "white", display: "flex",
                          alignItems: "center", justifyContent: "center",
                          height: 50, borderRadius: 15,
                          backgroundColor: (showSearchProduct || enableSave) ? "#55b88a" : "#dc2828",
                          fontSize: 13.5, fontWeight: 'bold', 
                          border: 'solid 3px #dc2828'
                        }}
                      >
                        <div className="new-line">
                          {`1\nOrder-Taking`}
                        </div>
                      </button> 
                      <button
                        onClick={() => {
                          setShowSelectDelivery(true)
                          setSelectedScriptIndex(4)
                          setSelectedStepIndex(8)
                        }}
                        style={{
                          padding: "0.5rem 0.75rem", width: 130,
                          color: "white", display: "flex",
                          alignItems: "center", justifyContent: "center",
                          height: 50, borderRadius: 15,
                          backgroundColor: showSelectDelivery ? "#55b88a" : "#dc2828",
                          fontSize: 13.5, fontWeight: 'bold', 
                          border: 'solid 3px #dc2828',
                          marginLeft: 10
                        }}
                      >
                        <div className="new-line">
                          {`2\nDelivery Fee`}
                        </div>
                      </button> 
                      <button
                        onClick={() => {
                          if (!alreadyBought) {
                            console.log(`user=${JSON.stringify(user)}; ${user.lastname}; ${user.firstname}; ${user.lastname === user.firstname}`)
                            const u = {...user}
                            if (user.lastname === user.firstname && user.lastname === user.address) {
                              u.firstname = "";
                              u.lastname = "";
                              u.address = "";
                              u.phoneNumber = "+63";
                              setUser(u);
                            }
                            else if (`+${user.address}` === user.phoneNumber) {
                              u.address = "";
                              u.phoneNumber = "+63";
                              setUser(u);
                            }
                            if (typeof props.serial !== 'undefined') {
                              u.hasTipidCard = 1;
                              u.tipidCardId = props.serial;
                              console.log(`final.user=${JSON.stringify(u)}`)
                              setUser(u);
                            }
                            setShowSearchProduct(false)
                            setShowUpdateUserDetails(true)
                            setShowSelectDelivery(false)
                            setShowDiscount(false)
                            setSelectedScriptIndex(5)
                            setSelectedStepIndex(Number(process.env.REACT_APP_IS_MD_CLUB) == 1 ? 11 : 10)
                          }
                        }}
                        style={{
                          padding: "0.5rem 0.75rem", width: 130,
                          color: "white", display: "flex",
                          alignItems: "center", justifyContent: "center",
                          height: 50, borderRadius: 15,
                          backgroundColor: showUpdateUserDetails ? "#55b88a" : "#dc2828",
                          fontSize: 13.5, fontWeight: 'bold', 
                          border: 'solid 3px #dc2828',
                          marginLeft: 10
                        }}
                      >
                        <div className="new-line">
                          {`3\nMember Info`}
                        </div>
                      </button> 
                      <button
                        onClick={() => {
                          setShowSearchMember(true)
                          setIsSearchReferror(true)
                          setSelectedScriptIndex(-1)
                          setSelectedStepIndex(3)
                        }}
                        style={{
                          padding: "0.5rem 0.75rem", width: 130,
                          color: "white", display: "flex",
                          alignItems: "center", justifyContent: "center",
                          height: 50, borderRadius: 15,
                          backgroundColor: isSearchReferror ? "#55b88a" : "#dc2828",
                          fontSize: 13.5, fontWeight: 'bold', 
                          border: 'solid 3px #dc2828',
                          marginLeft: 10
                        }}
                      >
                        <div className="new-line">
                          {`4\nReferral`}
                        </div>
                      </button> 
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', 
                        justifyContent: 'center', width: 700, marginTop: 0, height: 65 
                      }}
                    >
                      <button
                        onClick={() => {
                          setShowEditItem(false)
                          setSelectedScriptIndex(-1)
                          setSelectedStepIndex(13)
                          setControlType(2)
                        }}
                        style={{
                          padding: "0.5rem 0.75rem", width: 130,
                          color: "white", display: "flex",
                          alignItems: "center", justifyContent: "center",
                          height: 50, borderRadius: 15,
                          backgroundColor: showDiscount ? "#55b88a" : "#dc2828",
                          fontSize: 13.5, fontWeight: 'bold', 
                          border: 'solid 3px #dc2828',
                          marginLeft: 0
                        }}
                      >
                        <div className="new-line">
                          {`5\nDiscount`}
                        </div>
                      </button> 
                      <button
                        onClick={() => {
                          setShowConfirm(false);
                          setShowConfirm(true);
                          if (!alreadyBought) {
                            setShowFinalCost(false);
                            setShowConfirm(true);
                            setShowUserDetails(false)
                            _onSendSocket('Confirm', 'order', 'confirmOrder', null);
                            setSelectedScriptIndex(10)
                            setSelectedStepIndex(18)
                          }
                        }}
                        style={{
                          padding: "0.5rem 0.75rem", width: 130,
                          color: "white", display: "flex",
                          alignItems: "center", justifyContent: "center",
                          height: 50, borderRadius: 15,
                          backgroundColor: showConfirm ? "#55b88a" : "#dc2828",
                          fontSize: 13.5, fontWeight: 'bold', 
                          border: 'solid 3px #dc2828',
                          marginLeft: 10
                        }}
                      >
                        <div className="new-line">
                          {`6\nConfirm`}
                        </div>
                      </button>
                      <button
                        onClick={() => {
                          setShowDownload(true)
                          setShowThankYou(false)
                          setShowMayWe(false)
                          setShowText(false)
                          setShowConfirm(false)
                          setShowSms(false)
                          setShowUserDetails(false)
                          setShowFinalCost(false)
                          _onSendSocket('Download', 'App', 'showDownloadApp', null);
                          setSelectedScriptIndex(14)
                          setSelectedStepIndex(19)
                        }}
                        style={{
                          padding: "0.5rem 0.75rem", width: 130,
                          color: "white", display: "flex",
                          alignItems: "center", justifyContent: "center",
                          height: 50, borderRadius: 15,
                          backgroundColor: (showDownload || showThankYou) ? "#55b88a" : "#dc2828",
                          fontSize: 13.5, fontWeight: 'bold', 
                          border: 'solid 3px #dc2828',
                          marginLeft: 10
                        }}
                      >
                        <div className="new-line">
                          {`7\nDownload`}
                        </div>
                      </button> 
                    </div>
                  </div>
                }
                {controlType == 1 && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', 
                      justifyContent: 'flex-start', width: 700, marginTop: 10
                    }}
                  >
                     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', 
                        justifyContent: 'center', width: 700, marginTop: 5, height: 45, overflowAnchor: 'auto' 
                      }}
                    >
                      <div style={{position: 'relative', left: 0, height: 45, marginRight: 230}} onClick={() => {
                          setControlType(0)
                          setShowSearchProduct(false)
                          setShowEditItem(false)
                          setShowCapture(false)
                          setShowIsReseta(false);
                          setShowIsOrderlist(false); 
                          setSelectedScriptIndex(-1)
                          setSelectedStepIndex(7)
                          setShowEditItem(false)
                          if (finalized) {

                          }
                          else {
                            setSelectedScriptIndex(20)
                            setSelectedStepIndex(1)
                          }
                        }}
                      >
                        <img src={BackArrow} style={{width: 32, height: 32}} />
                      </div>
                      <p style={{fontSize: 18, fontWeight: 'bold', fontColor: '#404040', marginRight: 260}}>Order-Taking</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', 
                        justifyContent: 'center', width: 700, marginTop: 5, height: 65, overflowAnchor: 'auto' 
                      }}
                    >
                      <button
                        onClick={() => { 
                          if (!alreadyBought) {
                            setShowSearchProduct(true)
                            setShowEditItem(false)
                            setShowUpdateUserDetails(false)
                            setShowSelectDelivery(false)
                            setShowDiscount(false)
                            setShowUserDetails(false)
                            setSelectedScriptIndex(1)
                            setSelectedStepIndex(3)
                            setShowDownload(false)
                            setShowThankYou(false)
                          }
                        }}
                        style={{
                          padding: "0.5rem 0.75rem", width: 130,
                          color: "white", display: "flex",
                          alignItems: "center", justifyContent: "center",
                          height: 50, borderRadius: 15,
                          backgroundColor: (showSearchProduct || enableSave) ? "#55b88a" : "#dc2828",
                          fontSize: 13.5, fontWeight: 'bold', 
                          border: 'solid 3px #dc2828'
                        }}
                      >
                        Add Item
                      </button>  
                      <button
                        onClick={() => {
                          setShowSearchProduct(false);
                          setShowDiscount(false);
                          setShowSelectDelivery(false);
                          setShowEditItem(true);
                          setSelectedScriptIndex(-1);
                          setSelectedStepIndex(10)
                        }}
                        style={{
                          padding: "0.5rem 0.75rem", width: 130,
                          color: "white", display: "flex",
                          alignItems: "center", justifyContent: "center",
                          height: 50, borderRadius: 15,
                          backgroundColor: showEditItem ? "#55b88a" : "#dc2828",
                          fontSize: 13.5, fontWeight: 'bold', 
                          border: 'solid 3px #dc2828',
                          marginLeft: 10
                        }}
                      >
                        Edit Item
                      </button> 
                      <button
                        onClick={() => {
                          if (!alreadyBought) {
                            sendFinalize(false)
                            setFinalized(true)
                            setSelectedScriptIndex(3)
                            setSelectedStepIndex(6)
                          }
                        }}
                        style={{
                          padding: "0.5rem 0.75rem", width: 130,
                          color: "white", display: "flex",
                          alignItems: "center", justifyContent: "center",
                          height: 50, borderRadius: 15,
                          backgroundColor: showFinalize ? "#55b88a" : "#dc2828",
                          fontSize: 13.5, fontWeight: 'bold', 
                          border: 'solid 3px #dc2828',
                          marginLeft: 10
                        }}
                      >
                        Finalize
                      </button>
                      <button
                        onClick={() => {
                          setShowCapture(true);
                          setShowMayWe(true);
                          setShowNewCart(false);
                          setSelectedScriptIndex(13)
                          setSelectedStepIndex(-1)
                          _onSendSocket("Enable", "Capture", "enableCapture", null);
                        }}
                        style={{
                          padding: "0.5rem 0.75rem", width: 130,
                          color: "white", display: "flex",
                          alignItems: "center", justifyContent: "center",
                          height: 50, borderRadius: 15,
                          backgroundColor: showCapture ? "#55b88a" : "#dc2828",
                          fontSize: 13.5, fontWeight: 'bold', 
                          border: 'solid 3px #dc2828',
                          marginLeft: 10
                        }}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                }
                {controlType == 2 && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', 
                      justifyContent: 'flex-start', width: 700, marginTop: 10
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', 
                        justifyContent: 'center', width: 700, marginTop: 5, height: 25, overflowAnchor: 'auto' 
                      }}
                    >
                      <div style={{position: 'relative', left: 0, height: 25, marginRight: 230, marginBottom: 5}} onClick={() => {
                          setControlType(0)
                          setShowFinalCost(true);
                          setSelectedScriptIndex(9)
                          setSelectedStepIndex(16);
                          setShowDiscount(false);
                          setShowSummaryList(false);
                          _onSendSocket('Finalize', '', 'finalCart', null)
                        }}
                      >
                        <img src={BackArrow} style={{width: 32, height: 32}} />
                      </div>
                      <p style={{fontSize: 18, fontWeight: 'bold', fontColor: '#404040', marginRight: 260, marginTop: 10}}>Discount</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', 
                        justifyContent: 'center', width: 700, marginTop: 0, height: 60, overflowAnchor: 'auto' 
                      }}
                    >
                      <button
                        onClick={() => { 
                          if (!alreadyBought) {
                            setSelectedScriptIndex(-1)
                            setSelectedStepIndex(14)
                            setShowUpdateUserDetails(false);
                            setShowSummaryList(true);
                          }
                        }}
                        style={{
                          padding: "0.5rem 0.75rem", width: 130,
                          color: "white", display: "flex",
                          alignItems: "center", justifyContent: "center",
                          height: 50, borderRadius: 15,
                          backgroundColor: showSummaryList ? "#55b88a" : "#dc2828",
                          fontSize: 13.5, fontWeight: 'bold', 
                          border: 'solid 3px #dc2828'
                        }}
                      >
                        Summary List
                      </button>
                      <button
                        onClick={() => { 
                        }}
                        style={{
                          padding: "0.5rem 0.75rem", width: 130,
                          color: "white", display: "flex",
                          alignItems: "center", justifyContent: "center",
                          height: 50, borderRadius: 15,
                          backgroundColor: (showSearchProduct || enableSave) ? "#55b88a" : "#dc2828",
                          fontSize: 13.5, fontWeight: 'bold', 
                          border: 'solid 3px #dc2828',
                          marginLeft: 10
                        }}
                      >
                        Volume Discount
                      </button>
                      <button
                        onClick={() => { 
                        }}
                        style={{
                          padding: "0.5rem 0.75rem", width: 130,
                          color: "white", display: "flex",
                          alignItems: "center", justifyContent: "center",
                          height: 50, borderRadius: 15,
                          backgroundColor: (showSearchProduct || enableSave) ? "#55b88a" : "#dc2828",
                          fontSize: 13.5, fontWeight: 'bold', 
                          border: 'solid 3px #dc2828',
                          marginLeft: 10
                        }}
                      >
                        Member Discount
                      </button>  
                      <button
                        onClick={() => { 
                        }}
                        style={{
                          padding: "0.5rem 0.75rem", width: 130,
                          color: "white", display: "flex",
                          alignItems: "center", justifyContent: "center",
                          height: 50, borderRadius: 15,
                          backgroundColor: (showSearchProduct || enableSave) ? "#55b88a" : "#dc2828",
                          fontSize: 13.5, fontWeight: 'bold', 
                          border: 'solid 3px #dc2828',
                          marginLeft: 10
                        }}
                      >
                        Product Discount
                      </button>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', 
                        justifyContent: 'center', width: 700, marginTop: 0, height: 60, overflowAnchor: 'auto' 
                      }}
                    >
                      <button
                        onClick={() => { 
                        }}
                        style={{
                          padding: "0.5rem 0.75rem", width: 130,
                          color: "white", display: "flex",
                          alignItems: "center", justifyContent: "center",
                          height: 50, borderRadius: 15,
                          backgroundColor: (showSearchProduct || enableSave) ? "#55b88a" : "#dc2828",
                          fontSize: 13.5, fontWeight: 'bold', 
                          border: 'solid 3px #dc2828',
                        }}
                      >
                        Tipid Card Discount
                      </button>
                      <button
                        onClick={() => { 
                        }}
                        style={{
                          padding: "0.5rem 0.75rem", width: 130,
                          color: "white", display: "flex",
                          alignItems: "center", justifyContent: "center",
                          height: 50, borderRadius: 15,
                          backgroundColor: (showSearchProduct || enableSave) ? "#55b88a" : "#dc2828",
                          fontSize: 13.5, fontWeight: 'bold', 
                          border: 'solid 3px #dc2828',
                          marginLeft: 10
                        }}
                      >
                        Referral Discount
                      </button>
                      <button
                        onClick={() => { 
                        }}
                        style={{
                          padding: "0.5rem 0.75rem", width: 130,
                          color: "white", display: "flex",
                          alignItems: "center", justifyContent: "center",
                          height: 50, borderRadius: 15,
                          backgroundColor: (showSearchProduct || enableSave) ? "#55b88a" : "#dc2828",
                          fontSize: 13.5, fontWeight: 'bold', 
                          border: 'solid 3px #dc2828',
                          marginLeft: 10
                        }}
                      >
                        Reorder Discount
                      </button> 
                      <button
                        onClick={() => { 
                        }}
                        style={{
                          padding: "0.5rem 0.75rem", width: 130,
                          color: "white", display: "flex",
                          alignItems: "center", justifyContent: "center",
                          height: 50, borderRadius: 15,
                          backgroundColor: (showSearchProduct || enableSave) ? "#55b88a" : "#dc2828",
                          fontSize: 13.5, fontWeight: 'bold', 
                          border: 'solid 3px #dc2828',
                          marginLeft: 10
                        }}
                      >
                        SC/PWD Discount
                      </button>
                    </div>
                  </div>
                }
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', 
                justifyContent: 'flex-start', minWidth: 1100, maxWidth: 1100, marginTop: 10, height: 0, 
                border: 'solid 1px #9be0dd' }}
              />
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', 
                  justifyContent: 'center', minWidth: 1100, maxWidth: 1100, marginTop: 130, height: 70 
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', 
                  justifyContent: 'flex-start', minWidth: 735, maxWidth: 735, marginTop: 305, maxHeight: 625, 
                  overflowY: 'auto' }}
                >
                  {showSearchProduct && <div style={{backgroundColor: 'white', height: 630}}>
                      <div style={{height: 50, backgroundColor: 'transparent', width: 500, marginTop: 20, justifyContent: 'center'}}>
                        <form onSubmit={e => { e.preventDefault(); }}>
                          <input type="default" style={{width: '100%', fontSize: 16, textAlign: 'left'}} value={searchText} placeholder="Search"
                            onChange={(event) => {
                              if (event.target.key === 'Enter') {
                                event.preventDefault();
                              }
                              else {
                                onSearchChangeProduct(event.target.value)
                              }
                            }}
                          />
                        </form>   
                      </div>
                      <div style={{textDecoration: 'none', flexDirection: 'column', width: 500, height: h, justifyContent: 'center', alignItems: 'center'}} >
                        <div
                          style={{width: w, height: 620, backgroundColor: 'transparent', borderRadius: 20, justifyContent: 'flex-start', alignItems: 'center'}}
                        >
                          <div style={{display: "flex", width: 600, alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column'}}>
                            <div style={{marginTop: 20, width: 600, alignItems: 'center', marginLeft: w * 0.0, height: 400, overflowY: 'scroll'}}>
                                { products.map((item,index) => renderProductItem(item, index)) }
                                <div 
                                  style={{backgroundColor: 'transparent', width: w, alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: 0}}
                                >
                                </div>
                            </div>
                          </div>  
                        </div> 
                      </div>     
                    </div>
                  }
                  {enableSave && <div style={{backgroundColor: 'white', height: 630}}>
                      {_renderCurrentItem(currentItem, 0)}
                      <div style={{flexDirection: 'column', width: w, height: 200, backgroundColor: 'transparent', justifyContent: 'flex-start', 
                        alignItems: 'center', borderRadius: 20, marginTtop: -20}} 
                      >
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: w * 0.85, width: w * 1.25, top: 0,
                          borderTopLeftRadius: 0, borderTopRightRadius: 0, overflow: 'hidden'}}
                        >
                          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', 
                              justifyContent: 'center', marginTop: 40
                            }}
                          >
                            <button
                              onClick={() => {
                                setSelectedScriptIndex(2);
                                setSelectedStepIndex(5);
                                _onSaveButtonPress(user.id)
                              }}
                              style={{
                                padding: "0.5rem 0.75rem", width: 130,
                                color: "#404040", display: "flex",
                                alignItems: "center", justifyContent: "center",
                                height: 50, borderRadius: 25,
                                backgroundColor: "#ffcaca",
                                fontSize: 13.5, fontWeight: 'bold', 
                                border: 'solid 3px #dc2828'
                              }}
                            >
                              Save
                            </button> 
                            {/* <button
                              onClick={() => {
                                _onUpButtonPress(user.id)
                              }}
                              style={{
                                padding: "0.5rem 0.75rem", width: 130,
                                color: "#404040", display: "flex",
                                alignItems: "center", justifyContent: "center",
                                height: 50, borderRadius: 25,
                                backgroundColor: "#ffcaca",
                                fontSize: 13.5, fontWeight: 'bold', 
                                border: 'solid 3px #9be0dd',
                                marginLeft: 20
                              }}
                            >
                              Up
                            </button> 
                            <button
                              onClick={() => {
                                _onDownButtonPress(user.id)
                              }}
                              style={{
                                padding: "0.5rem 0.75rem", width: 130,
                                color: "#404040", display: "flex",
                                alignItems: "center", justifyContent: "center",
                                height: 50, borderRadius: 25,
                                backgroundColor: "#ffcaca",
                                fontSize: 13.5, fontWeight: 'bold', 
                                border: 'solid 3px #9be0dd',
                                marginLeft: 20
                              }}
                            >
                              Down
                            </button> 
                            <button
                              onClick={() => {
                                _onShowButtonPress(currentItem.id)
                              }}
                              style={{
                                padding: "0.5rem 0.75rem", width: 130,
                                color: "#404040", display: "flex",
                                alignItems: "center", justifyContent: "center",
                                height: 50, borderRadius: 25,
                                backgroundColor: "#ffcaca",
                                fontSize: 13.5, fontWeight: 'bold', 
                                border: 'solid 3px #9be0dd',
                                marginLeft: 20
                              }}
                            >
                              Show
                            </button> 
                            <button
                              onClick={() => {
                                _onHideButtonPress(user.id)
                              }}
                              style={{
                                padding: "0.5rem 0.75rem", width: 130,
                                color: "#404040", display: "flex",
                                alignItems: "center", justifyContent: "center",
                                height: 50, borderRadius: 25,
                                backgroundColor: "#ffcaca",
                                fontSize: 13.5, fontWeight: 'bold', 
                                border: 'solid 3px #9be0dd',
                                marginLeft: 20
                              }}
                            >
                              Hide
                            </button>  */}
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  {showSelectDelivery && <div className={classes.delFeeContainer}>
                      <div style={{display: 'flex', top: '50%', flexDirection: 'column', width: w * 0.8, height: 300, backgroundColor: 'white', justifyContent: 'center', 
                        alignItems: 'center', borderRadius: 20, top: 0, position: 'relative'}} 
                      >
                        <p style={{marginTop: 10, color: '#dc2828', fontSize: 18, width: w * 0.7, textAlign: 'center', fontWeight: '600'}} >Type of Delivery</p>
                        <div style={{top: 30, justifyContent: 'center'}}>
                          {deliveryTypes.map((rowData,index) => {
                            return <div key={rowData.id} style={{cursor: 'pointer', display: 'flex', flexDirection: 'column', width: w * 0.6, height: 80, justifyContent: 'center', alignItems: 'center'}} 
                            onClick={() => _onDeliveryTypePress(rowData)} 
                            >
                            <div
                              style={{display: 'flex', width: w * 0.6, height: 60, backgroundColor: '#9be0dd', borderRadius: 6, justifyContent: 'center', alignItems: 'center'}}
                            >
                              <div style={{display: 'flex', flexDirection: 'row'}}>
                                <p style={{textAlign: 'center', fontWeight: '500', color: '#dc2828', fontSize: 16, height: 12}} >{rowData.name}</p>
                                <p style={{textAlign: 'center', fontWeight: '500', color: '#404040', fontSize: 16, height: 12, marginLeft: 5}} >(₱{formatNumberWithComma(rowData.price, 2)})</p>
                              </div>
                              {rowData.isSelected && <img
                                  style={{width: 16, height: 16, position: 'absolute', marginLeft: '65%', marginBottom: '10%'}}
                                  src={CheckButton}
                                />
                              }
                            </div>
                            </div>
                          })}
                        </div>
                        <div style={{marginTop: 0}}>
                          <MyButton disabled={deliveryTypes.filter(item => item.isSelected).length == 0} label='Select' isLong={false} onPress={() => {
                            _onSelectDelFee()
                            setSelectedScriptIndex(-1)
                            setSelectedStepIndex(9)
                            setFinalized(false)
                          }}/>
                        </div>
                      </div>
                    </div>
                  } 
                  {showUpdateUserDetails && <div className={classes.cartContainer}>
                      <div style={{ width: '100%', alignItems: 'flex-start', justifyContent: 'center', minWidth: 350, maxWidth: 350, marginTop: '50px' }}>
                        <>
                        <div style={{marginTop: 30, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                          <div style={{display: 'flex', flexDirection: 'row', width: w * 1.6, justifyContent: 'center', alignItems: 'center'}} >
                            <p style={{marginRight: 10, fontSize: 16, marginTop: 10, width: w * 0.25}}>First Name:</p>
                            <form onSubmit={(event) => event.preventDefault()}>
                              <input type="text" style={{width: w * 0.7, fontSize: 20, textAlign: 'left'}} value={user.firstname} placeholder="First Name"
                                onChange={(event) => {
                                  if (event.target.key === 'Enter') {
                                    event.preventDefault();
                                  }
                                  else {
                                    setFirstName(event.target.value)
                                  }
                                }} maxLength="20"
                              />
                            </form>
                          </div>
                          <div style={{display: 'flex', flexDirection: 'row', width: w, justifyContent: 'center', alignItems: 'center'}} >
                            <p style={{marginRight: 10, fontSize: 16, marginTop: 10, width: w * 0.25}}>Last Name:</p>
                            <form onSubmit={(event) => event.preventDefault()}>
                              <input type="text" style={{width: w * 0.7, fontSize: 20, textAlign: 'left'}} value={user.lastname} placeholder="Last Name"
                                onChange={(event) => {
                                  if (event.target.key === 'Enter') {
                                    event.preventDefault();
                                  }
                                  else {
                                    setLastName(event.target.value)
                                  }
                                }} maxLength="20"
                              />
                            </form>
                          </div>
                          <div style={{display: 'flex', flexDirection: 'row', width: w, justifyContent: 'center', alignItems: 'center'}} >
                            <p style={{marginRight: 10, fontSize: 16, marginTop: 10, width: w * 0.25}}>Phone #:</p>
                            <form onSubmit={(event) => event.preventDefault()}>
                              <input type="text" style={{width: w * 0.7, fontSize: 20, textAlign: 'left'}} value={user.phoneNumber} placeholder="format: +639171234567"
                                onChange={(event) => {
                                  if (event.target.key === 'Enter') {
                                    event.preventDefault();
                                  }
                                  else {
                                    setPhoneNumber(event.target.value)
                                  }
                                }} maxLength="13"
                              />
                            </form>
                          </div>
                          <div style={{display: 'flex', flexDirection: 'row', width: w, justifyContent: 'center', alignItems: 'flex-start'}} >
                            <p style={{marginRight: 10, fontSize: 16, marginTop: 10, width: w * 0.25}}>Address:</p>
                            <form onSubmit={(event) => event.preventDefault()}>
                              <textarea type="textarea" style={{width: w * 0.7, fontSize: 20, textAlign: 'left', marginTop: 10, minHeight: 100, maxHeight: 150}} value={user.address} placeholder="Address"
                                onChange={(event) => setAddress(event.target.value)} maxLength="100" 
                              />
                              {/* <Autocomplete
                                apiKey={process.env.REACT_APP_GOOGLE_APIKEY}
                                style={{ width: "90%" }}
                                onPlaceSelected={(place) => {
                                  console.log(place);
                                }}
                                options={{
                                  types: ["(street_address)"],
                                  componentRestrictions: { country: "ph" },
                                }}
                                defaultValue={user.address}
                              /> */}
                            </form>
                          </div>
                          {/* <div style={{display: 'flex', flexDirection: 'row', width: w, justifyContent: 'center', alignItems: 'center'}} >
                            <form onSubmit={(event) => event.preventDefault()} style={{width: w, marginTop: 10, display: 'flex', flexDirection: 'row', marginRight: 10}}>
                              <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div className="radio" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                  <input
                                    style={{height: 20, width: 20, marginTop: 0, marginRight: 0}}
                                    type="radio"
                                    value="SC"
                                    checked={value == "SC"}
                                    onChange={() => setValue("SC")}
                                  />
                                  <label style={{width: w * 0.20 + 2, marginLeft: 15, marginTop: 5}}>
                                    SC ID#:
                                  </label>
                                  <form onSubmit={(event) => event.preventDefault()}>
                                    <input type="text" style={{width: w * 0.7, fontSize: 20, textAlign: 'left'}} 
                                      value={value == "SC" ? user.scPwdId : ""} 
                                      placeholder="SC ID #"
                                      onChange={(event) => {
                                        console.log(`value=${value}`)
                                        if (value == "SC") {
                                          if (event.target.key === 'Enter') {
                                            event.preventDefault();
                                          }
                                          else {
                                            setScPwdId(event.target.value)
                                          }
                                        }
                                        else {
                                          setScPwdId("");
                                        }
                                      }} maxLength="10"
                                    />
                                  </form>
                                </div>
                                <div className="radio" style={{display: 'flex', flexDirection: 'row', marginTop: 10, alignItems: 'center'}}>
                                  <input
                                    style={{height: 20, width: 20, marginTop: 0, marginRight: 0}}
                                    type="radio"
                                    value="PWD"
                                    checked={value == "PWD"}
                                    onChange={() => setValue("PWD")}
                                  />
                                  <label style={{width: w * 0.2 + 2, marginLeft: 15, marginTop: 5}}>
                                    PWD ID#:
                                  </label>
                                  <form onSubmit={(event) => event.preventDefault()}>
                                    <input type="text" style={{width: w * 0.7, fontSize: 20, textAlign: 'left'}} 
                                      value={value == "PWD" ? user.scPwdId : ""} 
                                      placeholder="PWD ID #"
                                      onChange={(event) => {
                                        console.log(`value=${value}`)
                                        if (value == "PWD") {
                                          if (event.target.key === 'Enter') {
                                            event.preventDefault();
                                          }
                                          else {
                                            setScPwdId(event.target.value)
                                          }
                                        }
                                        else {
                                          setScPwdId("");
                                        }
                                      }} maxLength="10"
                                    />
                                  </form>
                                </div>
                              </div>
                            </form>
                          </div> */}
                          <div style={{display: 'flex', flexDirection: 'row', width: w, justifyContent: 'center', alignItems: 'center'}} >
                            <>
                              <form onSubmit={(event) => event.preventDefault()} style={{width: w, marginTop: 10, display: 'flex', flexDirection: 'column', marginRight: 10}}>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                  <div className="radio" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} onClick={() => {
                                    console.log(`here`)
                                    setValue("SC")}
                                  }>
                                    <input
                                      style={{height: 20, width: 20, marginTop: 0, marginRight: 0}}
                                      type="radio"
                                      value="SC"
                                      checked={value == "SC"}
                                      onChange={() => {}}
                                    />
                                    <label style={{width: w * 0.20 + 2, marginLeft: 15, marginTop: 5}}>
                                      SC ID#:
                                    </label>
                                  </div>
                                  <div className="radio" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10}} onClick={() => {
                                    console.log(`there`)
                                    setValue("PWD")}
                                  }>
                                    <input
                                      style={{height: 20, width: 20, marginTop: 0, marginRight: 0}}
                                      type="radio"
                                      value="PWD"
                                      checked={value == "PWD"}
                                      onChange={() => {}}
                                    />
                                    <label style={{width: w * 0.2 + 2, marginLeft: 15, marginTop: 5}}>
                                      PWD ID#:
                                    </label>
                                  </div>
                                </div>
                              </form>
                            </>
                            <>
                              <form onSubmit={(event) => event.preventDefault()} style={{width: w, marginTop: 10, display: 'flex', flexDirection: 'columnx', marginRight: 10}}>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                  <div className="radio" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <form onSubmit={(event) => event.preventDefault()}>
                                      <input type="text" style={{width: w * 0.7, fontSize: 20, textAlign: 'left'}} 
                                        value={value == "SC" ? user.scPwdId : ""} 
                                        placeholder="SC ID #"
                                        onChange={(event) => {
                                          console.log(`value=${value}`)
                                          if (value == "SC") {
                                            if (event.target.key === 'Enter') {
                                              event.preventDefault();
                                            }
                                            else {
                                              console.log(`event.target.value:${event.target.value}`)
                                              setScPwdId(event.target.value)
                                            }
                                          }
                                          else {
                                            setScPwdId("");
                                          }
                                        }} maxLength="10"
                                      />
                                    </form>
                                  </div>
                                  <div className="radio" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
                                    <form onSubmit={(event) => event.preventDefault()}>
                                      <input type="text" style={{width: w * 0.7, fontSize: 20, textAlign: 'left'}} 
                                        value={value == "PWD" ? user.scPwdId : ""} 
                                        placeholder="PWD ID #"
                                        onChange={(event) => {
                                          console.log(`value=${value}`)
                                          if (value == "PWD") {
                                            if (event.target.key === 'Enter') {
                                              event.preventDefault();
                                            }
                                            else {
                                              setScPwdId(event.target.value)
                                            }
                                          }
                                          else {
                                            setScPwdId("");
                                          }
                                        }} maxLength="10"
                                      />
                                    </form>
                                  </div>
                                </div>
                              </form>
                            </>
                          </div>
                          {user.scPwdIdUrl && <div style={{marginTop: 10, marginLeft: w * 0.05}}>
                              <img src={user.scPwdIdUrl} style={{width: w, height: w, resize: 'contain'}} />
                            </div>
                          }
                          <div style={{display: 'flex', flexDirection: 'row', width: w, justifyContent: 'flex-start', alignItems: 'center', marginTop: 10}} >
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}} onClick={() => setHasTipidCard()}>
                              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 20, height: 20, borderWidth: 2, border: '3px solid #000000', borderRadius: 3, marginRight: 5}}>
                                {user.hasTipidCard == 1 && <img src={CheckBoxCheck} style={{width: 20, height: 20}}/>}
                              </div>
                            </div>
                            <p style={{marginRight: 10, fontSize: 16, marginTop: 15}}>Tipid Card:</p>
                            <form onSubmit={(event) => event.preventDefault()}>
                              <input type="text" style={{width: w * 0.7, fontSize: 20, textAlign: 'left'}} 
                                value={user.tipidCardId} 
                                placeholder="Tipid Card #"
                                onChange={(event) => {
                                  if (event.target.key === 'Enter') {
                                    event.preventDefault();
                                  }
                                  else {
                                    setTipidCardId(event.target.value)
                                  }
                                }} maxLength="10"
                              />
                            </form>
                          </div>
                        </div>
                        {user.tipidCardIdUrl && <div style={{marginTop: 10, marginLeft: w * 0.05}}>
                            <img src={user.tipidCardIdUrl} style={{width: w, height: w, resize: 'cover'}} />
                          </div>
                        }
                        <div 
                          style={{display: 'flex', backgroundColor: 'transparent', marginLeft: -20, width: w, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', marginTop: 30, marginBottom: 30, flexDirection: 'row'}}
                        >
                          <MyButton width={90} label={`Save`} onPress={() => {
                              updateUser()
                            }}
                          />
                          <MyButton width={90} label={`Send ID`} disabled={!showUserDetails} onPress={() => {
                              setIdOn(true)
                              _onSendSocket('ID', 'On', 'sendIdOn', null)
                            }}
                          />
                          <MyButton width={90} label={`Send TC`} disabled={!showUserDetails || user.hasTipidCard == 0} onPress={() => {
                              setTipidIdOn(true)
                              _onSendSocket('TC', 'On', 'sendTCOn', null)
                            }}
                          />
                          <MyButton width={90} label={`Back`} onPress={() => {
                              updateUser()
                              setShowUpdateUserDetails(false);
                              setUpdatedUserDetails(true);
                              setShowMayWe(false);
                              setShowDiscount(false);
                              setShowUserDetails(true);
                              setShowText(false);
                              _onDrugstorePress(false);
                              setShowNewCart(false);  
                              setSelectedScriptIndex(17)
                              setSelectedStepIndex(12)
                            }}
                          />
                        </div>
                        </>
                      </div>
                    </div>
                  }
                  {showSummaryList && <div className={classes.delFeeContainer}>
                      <div style={{display: 'flex', top: '50%', flexDirection: 'column', width: w * 0.8, height: 300, backgroundColor: 'white', justifyContent: 'center', 
                        alignItems: 'center', borderRadius: 20, top: 0, position: 'relative'}} 
                      >
                        <div style={{marginTop: 0}}>
                          <MyButton label='Show' isLong={false} onPress={() => {
                            setShowDiscount(true);
                            sendDiscount()
                            setSelectedScriptIndex(8);
                            setSelectedStepIndex(15)
                          }}/>
                        </div>
                      </div>
                    </div>
                  } 
                  {showDownload && <div className={classes.delFeeContainer}>
                      <div style={{display: 'flex', top: '50%', flexDirection: 'column', width: w * 0.8, height: 300, backgroundColor: 'white', justifyContent: 'center', 
                        alignItems: 'center', borderRadius: 20, top: 0, position: 'relative'}} 
                      >
                        <div style={{marginTop: 0}}>
                          <MyButton label='Steps' isLong={false} onPress={() => {
                            setShowDownload2(true);
                            setShowDownload(false);
                            setSelectedScriptIndex(18)
                            setSelectedStepIndex(20);
                            _onSendSocket('Promo Code', 'Steps', 'showPromoCodeSteps', null)
                          }}/>
                        </div>
                      </div>
                    </div>
                  } 
                  {showEditItem && <div className={classes.delFeeContainer}>
                      <div style={{display: 'flex', top: '50%', flexDirection: 'column', width: w * 0.8, height: 600, backgroundColor: 'white', justifyContent: 'center', 
                        alignItems: 'center', borderRadius: 20, top: 0, position: 'relative'}} 
                      >
                        <div style={{marginTop: 0, backgroundColor: 'white'}}>
                          { selectedSkus.map((item,index) => renderItemSwipeToDelete(item, index)) }
                          <div style={{flexDirection: 'column', width: w * 0.8, height: 200, backgroundColor: 'transparent', justifyContent: 'flex-start', 
                            alignItems: 'center', borderRadius: 20, marginTop: 50}} 
                          >
                            <div style={{ display: 'flex', flexDirection: 'row', width: w, alignItems: 'center', 
                                justifyContent: 'center', position: 'absolute', bottom: h * 0.38
                              }}
                            >
                              <button
                                onClick={() => {
                                  setShowEditItem(false)
                                  setSelectedScriptIndex(2);
                                  setSelectedStepIndex(5);
                                  _onSaveButtonPress(user.id)
                                }}
                                style={{
                                  padding: "0.5rem 0.75rem", width: 130,
                                  color: "#404040", display: "flex",
                                  alignItems: "center", justifyContent: "center",
                                  height: 50, borderRadius: 25,
                                  backgroundColor: "#ffcaca",
                                  fontSize: 13.5, fontWeight: 'bold', 
                                  border: 'solid 3px #dc2828'
                                }}
                              >
                                Save
                              </button> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  {/* reorder */}
                  {showMayWe && props.callType == 1 && !showReorder && <div className={classes.delFeeContainer}> 
                      <div style={{display: 'flex', top: '50%', flexDirection: 'column', width: w * 0.8, height: 300, backgroundColor: 'white', justifyContent: 'center', 
                        alignItems: 'center', borderRadius: 20, top: 0, position: 'relative'}} 
                      >
                        <div style={{marginTop: 0}}>
                          <MyButton label='Reorder' isLong={false} onPress={() => {
                            reorderPressed()
                          }}/>
                        </div>
                      </div>
                    </div>
                  } 
                  { showReorder && <div style={{display: 'flex', top: '50%', flexDirection: 'column', width: w, height: 600, backgroundColor: 'white', justifyContent: 'flex-start', 
                      alignItems: 'center', borderRadius: 20, top: 0, position: 'relative'}} 
                    >
                      <TabTop tabArr={tabArr} action={(index) => tabAction(index)} />
                      <div style={{height: height * 0.43, overflowY: 'auto', overflowX: 'hidden', width: w, backgroundColor: 'transparent', top: 60}}>
                        {(tabIndex == 0 ? list : alphalist).map((item,index) => tabIndex == 0 ? _renderReorderData(item, index) : _renderReorderSelectedItemAlpha(item, index))}
                      </div>
                      <div style={{marginTop: 0}}>
                        <MyButton label='Save' isLong={false} onPress={() => {_onSaveReorder()}}/>
                      </div>
                    </div>
                  }
                  {/* {showCapture && <div className={classes.delFeeContainer}>
                      <div style={{display: 'flex', top: '50%', flexDirection: 'column', width: w * 0.8, height: 300, backgroundColor: 'white', justifyContent: 'center', 
                        alignItems: 'center', borderRadius: 20, top: 0, position: 'relative'}} 
                      >
                        <div style={{marginTop: 0}}>
                          <MyButton label='Capture' isLong={false} onPress={onStartCapture}/>
                        </div>
                      </div>
                    </div>
                  }  */}
                  {showIsReseta && resetaImage && <div className={classes.delFeeContainer}>
                    <img src={resetaImage} style={{width: 710}}/>
                    </div>
                  }
                  {showIsOrderlist && orderlistImage && <div className={classes.delFeeContainer}>
                    <img src={orderlistImage} style={{width: 710}}/>
                    </div>
                  }
                  {showSearchMember && <div style={{backgroundColor: 'white', height: 630}}>
                      <div style={{height: 50, backgroundColor: 'transparent', width: 500, marginTop: 20, justifyContent: 'center', display: 'flex', flexDirection: 'row'}}>
                        <form onSubmit={e => { e.preventDefault(); }}>
                          <input type="default" style={{width: 500, fontSize: 16, textAlign: 'left'}} value={searchText} placeholder="Search"
                            onChange={(event) => {
                              if (event.target.key === 'Enter') {
                                event.preventDefault();
                              }
                              else {
                                onSearchChange(event.target.value)
                              }
                            }}
                          />
                        </form>  
                        {Number(process.env.REACT_APP_IS_MD_CLUB) == 1 && 
                          <div style={{marginLeft: 10}} onClick={() => {
                              _addMember()
                              setSelectedScriptIndex(-1)
                              setSelectedStepIndex(10)
                            }}>
                            <img src={rxPlus} style={{width: 30, height: 30}}/>
                          </div>
                        } 
                      </div>
                      <div style={{textDecoration: 'none', flexDirection: 'column', width: 500, height: h, justifyContent: 'center', alignItems: 'center'}} >
                        <div
                          style={{width: w, height: 620, backgroundColor: 'transparent', borderRadius: 20, justifyContent: 'flex-start', alignItems: 'center'}}
                        >
                          <div style={{display: "flex", width: 600, alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column'}}>
                            <div style={{marginTop: 20, width: 600, alignItems: 'center', marginLeft: w * 0.0, height: 400, overflowY: 'auto'}}>
                                { members.map((item,index) => renderMemberItem(item, index)) }
                                <div 
                                  style={{backgroundColor: 'transparent', width: w, alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: 0}}
                                >
                                </div>
                            </div>
                          </div>  
                        </div> 
                      </div>     
                    </div>
                  }
                  {showAddMember && <div className={classes.cartContainer}>
                      <div style={{ width: '100%', alignItems: 'flex-start', justifyContent: 'center', minWidth: 350, maxWidth: 350, marginTop: '50px' }}>
                        <>
                        <div style={{marginTop: 30, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                          <div style={{display: 'flex', flexDirection: 'row', width: w * 1.6, justifyContent: 'center', alignItems: 'center'}} >
                            <p style={{marginRight: 10, fontSize: 16, marginTop: 10, width: w * 0.25}}>First Name:</p>
                            <form onSubmit={(event) => event.preventDefault()}>
                              <input type="text" style={{width: w * 0.7, fontSize: 20, textAlign: 'left'}} value={user.firstname} placeholder="First Name"
                                onChange={(event) => {
                                  if (event.target.key === 'Enter') {
                                    event.preventDefault();
                                  }
                                  else {
                                    setFirstName(event.target.value)
                                  }
                                }} maxLength="20"
                              />
                            </form>
                          </div>
                          <div style={{display: 'flex', flexDirection: 'row', width: w, justifyContent: 'center', alignItems: 'center'}} >
                            <p style={{marginRight: 10, fontSize: 16, marginTop: 10, width: w * 0.25}}>Last Name:</p>
                            <form onSubmit={(event) => event.preventDefault()}>
                              <input type="text" style={{width: w * 0.7, fontSize: 20, textAlign: 'left'}} value={user.lastname} placeholder="Last Name"
                                onChange={(event) => {
                                  if (event.target.key === 'Enter') {
                                    event.preventDefault();
                                  }
                                  else {
                                    setLastName(event.target.value)
                                  }
                                }} maxLength="20"
                              />
                            </form>
                          </div>
                        </div>
                        <div 
                          style={{display: 'flex', backgroundColor: 'transparent', width: w, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', marginTop: 30, marginBottom: 30, flexDirection: 'row'}}
                        >
                          <MyButton label={`Save`} onPress={() => {
                              const code = randomString();
                              setPhoneNumber(code);
                              setAddress(code);
                              setSelectedStepIndex(1);
                              register(code)
                              setShowAddMember(false);
                              setShowMayWe(false);
                              setShowDiscount(false);
                              setShowText(false);
                              setShowNewCart(false);  
                            }}
                          />
                        </div>
                        </>
                      </div>
                    </div>
                  }
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', 
                  justifyContent: 'flex-start', minWidth: 0, maxWidth: 0, marginTop: 300, height: 630, 
                  border: 'solid 1px #9be0dd' }}
                />
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', 
                  justifyContent: 'flex-start', minWidth: 360, maxWidth: 360, marginTop: 305, height: 625, 
                  overflowY: 'auto' }}
                >
                  {selectedScriptIndex >= 0 &&
                    <>
                      <p style={{fontSize: 48, fontWeight: '500', color: '#dc2828', marginTop: 20}}>Script:</p>
                      {script.length > 0 && script.filter(item => item.id == selectedScriptIndex).map((item,index) => _renderScriptItem(item,index))}
                    </>
                  }
                  {selectedStepIndex >= 0 &&
                    <>
                      <p style={{fontSize: 36, fontWeight: '500', color: '#dc2828', marginTop: 50, fontStyle: 'italic'}}>Step:</p>
                      {steps.length > 0 && steps.filter(item => item.id == selectedStepIndex).map((item,index) => _renderToDoItem(item,index))}
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        <div style={{display: 'flex', position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'white', alignItems: 'center', justifyContent: 'flex-start', width: '100%'}}>
          <div style={{position: 'absolute', backgroundColor: 'transparent', bottom: 0, height: 30, width: '1500px', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{color: '#dc2828', textAlign: 'center', fontSize: 24, fontWeight: '700'}}>{Number(process.env.REACT_APP_IS_MD_CLUB) == 1 ? 'RxCallCenter' : 'RxPharmacist'}</p>
          </div>
          <InnerPageHeader hasBackBtn goBackClick={() => _onBackPress()} title="" style={{position: 'absolute', left: 0}}
            // rightButtonLabel="INPUT"
            // onRightPress={() => navigate(routes.cartInput, { state: {userId} })}
          />
          <div style={{position: 'absolute', left: 50}} onClick={() => setIsRecordOn(!isRecordOn)}>
              <img className={isRecordOn ? 'blink' : ''} src={isRecordOn ? RecordOn : RecordOff} style={{width: 50, height: 50, marginTop: 10}}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GetOrder;


// {!showSms && selectedSkus.map((item,index) => _renderItem({item,index}, w, h))}