import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  getOrderContainer: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
}));