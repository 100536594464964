import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useStyles from './styles';
import useWindowDimensions from '../../../utils/windowDimention';
import Loader from "../../../Components/Common/Loader";
import InnerPageHeader from '../../../Components/InnerPageHeader';
import Placeholder from '../../../assets/images/common-images/placeHolder.png';
import ReactTimeAgo from 'react-time-ago';
import {getPlaceHolder} from '../../../utils/utils';
// import Header from '../../../assets/images/header-images/Save_1.png';
// import Header2 from '../../../assets/images/header-images/Save_2.png';
import innerGo from '../../../assets/images/common-images/go.png';
import Kailan from '../../../assets/images/common-images/kailan.png';
import rxPlus from '../../../assets/images/common-images/plusIcon.png';
import rxMinus from '../../../assets/images/common-images/minusIcon.png';
import rxSavingsTagRed from '../../../assets/images/common-images/savingsTagRed.png';
import Okay from '../../../assets/images/common-images/okay.png'
import CheckBoxCheck from '../../../assets/images/common-images/checkBoxCheck.png';
import {formatNumberWithComma} from '../../../utils/utils';
import Moment from 'moment';
import SwipeToDelete from 'react-swipe-to-delete-component';
// import 'react-swipe-to-delete-component/dist/swipe-to-delete.css';
import "./swipe-to-delete.css"
import { io } from "socket.io-client";
import packageJson from '../../../../package.json';

import {
  getCart, getDrugstore, getDiscountTypesPerDrugstore, getParameters, uploadBought, getUserProfile, postNotification, searchBrandOrGeneric, checkPhoneNumber, registerRx, getDeliveryTypes
} from "../../../services/ApiService";
import { ids, routes } from '../../../constants';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import MyButton from '../../../Components/Common/MyButton';

let firstClientX = null;
let firstClientY = null;
let clientX = null;
let clientY = null;

const socket = io.connect(process.env.REACT_APP_SOCKET_URL);

socket.on("connect", (data) => {
  // console.log(`socket.data=${JSON.stringify(data)}`)  
})

const Cart = ({ history}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [reseta, setReseta] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [dayId, setDayId] = useState(7);
  const [level, setLevel] = useState(0);
  const [ds, setDs] = useState([]);
  const [dsData, setDsData] = useState([]);
  const [discountTypes, setDiscountTypes] = useState([]);
  const [selectedDs, setSelectedDs] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [discountTypesData, setDiscountTypesData] = useState([]);
  const [deliveryFee, setDeliveryFee] = useState(50);
  const [taxPercentage, setTaxPercentage] = useState(0.1);
  const [userData, setUserData] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [products, setProducts] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [finalCart, setFinalCart] = useState(false);
  const [selectedDeliveryType, setSelectedDeliveryType] = useState();
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [updateDetails, setUpdateDetails] = useState(false);
  const [deliveryId, setDeliveryId] = useState(0);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const props = useLocation().state;
  const [tabArr, setTabArr] = useState([
    { tabId: 7, tabName: '7 days', isSelect: true },
    { tabId: 14, tabName: '14 days', isSelect: false },
    { tabId: 28, tabName: '28 days', isSelect: false },
  ])

  const fetchData = async () => {
    try {
      setLoader(true);
      // console.log(`props=${JSON.stringify(props)}`);
      if (props !== null) {
        if (props.userId) {
          const response = await getCart({userId: props.userId});
          // console.log(`response2=${JSON.stringify(response)}`)
          if (response) {
            setData(response.data.data);
            setFilteredData(response.data.data);
          }
        }
      }
      const response1 = await getDrugstore();
      if (response1) {
        setDsData(response1.data.data);
      }
      const response2 = await getDiscountTypesPerDrugstore()
      if (response2) {
        setDiscountTypes(response2.data);
      }
      const response3 = await getParameters();
      if (response3) {
        // const param1 = response3.data.filter(item => {return item.id == 26})
        // if (param1.length > 0) {
        //     setDeliveryFee(param1[0].value);
        // }
        const param2 = response3.data.filter(item => {return item.id == 27})
        if (param2.length > 0) {
            setTaxPercentage(param2[0].value / 100);
        }
      }
      // const response4 = await getUserProfile();
      // if (response4) {
      //   setUserData(response4.data.data)
      // }
      // console.log("response", response);
      const response4 = await getDeliveryTypes();
      if (response4) {
        setSelectedDeliveryType(response4.data.data.filter(item => item.isSelected)[0]);
        setDeliveryFee(response4.data.data.filter(item => item.isSelected)[0].price);
        setDeliveryTypes(response4.data.data);
      }
    } catch (e) {
      setIsLoaded(true);
      setLoader(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (finalCart) {
      onDrugstoreClick();
    }
  }, [finalCart])

  useEffect(() => {
    if (isConfirmed) {
      buy();
    }
  }, [isConfirmed])

  useEffect(() => {
    if (deliveryId > 0) {
      const selected = deliveryTypes.filter(item => item.id == deliveryId)[0];
      setDeliveryFee(selected.price);
      setSelectedDeliveryType(selected);
    }
  }, [deliveryId])

  useEffect(() => {
    fetchData();
    if (props.userId) {
      socket.emit("online", { userId: props.userId });
      socket.on("newCart", async (data) => {
        const response = await getCart({userId: props.userId});
        // console.log(`response2=${JSON.stringify(response)}`)
        if (response) {
          setData(response.data.data);
          setFilteredData(response.data.data);
          setLevel(0);
        }
      })
      socket.on("finalCart", (data) => {
        console.log('finalCart')
        setFinalCart(false);
        setFinalCart(true);
      })
      socket.on("showDiscount", (data) => {
        console.log('showDiscount')
        setFinalCart(false);
        setLevel(11);
      })
      socket.on("setDelivery", (data) => {
        console.log(`setDelivery; data=${data}`)
        setDeliveryId(0);
        setDeliveryId(Number(data));
      })
      socket.on("updateUserDetails", (data) => {
        console.log(`updateUserDetails; data=${data}`)
        setPhoneNumber(data);
        setUpdateDetails(false);
        setUpdateDetails(true);
      })
      socket.on("confirmOrder", (data) => {
        console.log('confirmOrder');
        setIsConfirmed(true);
      })
    }
  }, []);

  useEffect(() => {
    if (phoneNumber && updateDetails) {
      checkPhone();
    }
  }, [updateDetails])

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('touchstart', touchStart);
    window.addEventListener('touchmove', preventTouch, { passive: false });
    return () => {
      window.removeEventListener('touchstart', touchStart);
      window.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const tabAction = (tabId) => {
    let tempData = [...tabArr
    .map(item => {
      if (item.tabId == tabId) {
        return {
          ...item,
          isSelect: true
        }
      }
      else {
        return {
          ...item,
          isSelect: false
        }
      }
    })];
    setTabArr([...tempData]);
    setDayId(tabId);
  }

  const onDoneClick = () => {
    // const total = computeTotal();
    // const param = {
    //   paymentOptionId: 40, //COD
    //   locationId: data[0].locationId,
    //   total,
    //   teamLeaderId: data[0].teamLeaderId,
    //   teamLeaderName: `${data[0].teamLeaderFirstname} ${data[0].teamLeaderLastname}`,
    //   teamLeaderAddress: data[0].teamLeaderAddress,
    //   teamLeaderPhoneNumber: data[0].teamLeaderPhoneNumber,
    //   deliveryDate: `${nextWeek()} - 10:00AM`,
    //   sponsorId: data[0].sponsorId,
    //   sponsor: data[0].sponsorDisplayName,
    //   details: data.map((item) => {
    //     return {
    //       savingsId: item.id,
    //       genericsId: item.generic.id,
    //       brandedId: item.brand.id,
    //       dosageId: item.dosage.id,
    //       discountId: item.brand.discountId,
    //       umaga: item.umaga,
    //       tanghali: item.tanghali,
    //       gabi: item.gabi,
    //       remarks: item.remarks,
    //       quantity: (item.umaga + item.tanghali + item.gabi) * dayId,
    //       price: item.brand.price,
    //       days: dayId
    //     }
    //   })
    // }

    // uploadBought(param)
    // .then((data) => {
    // })
    // .catch(e => {
    //     console.warn('upload bought ERROR', e.message);
    // });

    // const notifParam = {
    //   "destinationUserId": props.userData.id,
    //   "notificationTypeId": ids.notificationTypeBoughtFromStore,
    //   "textFormat": `TANDAAN: Ihahatid kay Team Leader %NAME% ang mga order mong gamot sa %DATE%.`,
    //   "textValues": { "DATE": nextWeek(), "NAME": `${data[0].teamLeaderFirstname} ${data[0].teamLeaderLastname}`},
    //   "expiryDate": Moment(new Date()).add(1, 'w')
    // }
    // // console.log(`notifParam=${JSON.stringify(notifParam)}`)
    // postNotification(notifParam)
    // .then(({data}) => {
      
    // })
    // .catch(e => {
    //   console.warn('post notification ERROR', e.message);
    // });

    // navigate(-1);
  }

  const addQty = (rowData) => {
    let tempGamot = [...data
    .map(item => {
      if (item.brand.id == rowData.brand.id && item.quantity < 100) {
        return {
          ...item,
          quantity: item.quantity + 1
        }
      }
      else {
        return item
      }
    })];
    setData(tempGamot)
  }

  const minusQty = (rowData) => {
    let tempGamot = [...data
    .map(item => {
      if (item.brand.id == rowData.brand.id && item.quantity > 0) {
        return {
          ...item,
          quantity: item.quantity - 1
        }
      }
      else {
        return item
      }
    })];
    setData(tempGamot)
  }

  useEffect(() => {
    console.log(`ds=${JSON.stringify(ds)}`)
    if (ds.length > 0) {
      console.log(`ds=${JSON.stringify(ds)}`)
      const selected = ds.filter(item => !item.hasBorder)
      // console.log(`selected=${JSON.stringify(selected)}`)
      // console.log(`selected=${JSON.stringify(selected)}; this.state.selectedDs=${JSON.stringify(this.state.selectedDs)}`)
      if (selected.length > 0) {
        onDsRowPress(selected[0]);
        setSelectedDs(selected[0]);
        setLevel(2);
      }
    }
  }, [ds])

  const onDrugstoreClick = () => {
    // this._updateCart();
    console.log(`dsData=${JSON.stringify(dsData)}`)
    let tempData = [];
    dsData.map(store => {
      let total = 0;
      let details = [];
      // data.map(selected => {
      filteredData.map(selected => {
        const storeItem = store.details.find(i => i.brand.id == selected.brand.id)
        if (storeItem) {
          total += storeItem.brand.price * selected.quantity;
          details.push({
            ...storeItem,
            quantity: selected.quantity
          })
        }
      })
      tempData.push({
        ...store,
        total,
        details: [...details]
      })
    })
    setDs(tempData);
    // setLevel(1);
  }

  const getDiscountText = (discount) => {
    const w = width * 0.5;

    switch(discount) {
      case 0:
        return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 95}}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 10}}>
            <p style={{color: 'white', fontSize: 48, fontWeight: '600', fontFamily: "CanaroMedium", height: 35}}>{discount}</p>
            <p style={{color: 'white', fontSize: 12, position: 'absolute', fontFamily: "CanaroBook", marginTop: 34, marginLeft: 48}}>%</p>
          </div>
          <p style={{color: 'white', fontSize: 12, marginTop: 25}}>Discount</p>
        </div> 
      case 10:
        return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 75}}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 30}}>
            <p style={{color: 'white', fontSize: 10, position: 'absolute', fontFamily: "CanaroBook", marginBottom: 55}}>Get</p>
            <p style={{color: 'white', fontSize: 48, width: 18, fontWeight: '600', fontFamily: "CanaroMedium", height: 60}}>1</p>
            <p style={{color: 'white', fontSize: 48, fontWeight: '600', fontFamily: "CanaroMedium", height: 60}}>0</p>
            <p style={{color: 'white', fontSize: 12, position: 'absolute', fontFamily: "CanaroBook", marginTop: 15, marginLeft: 65}}>%</p>
          </div>
          <p style={{color: 'white', fontSize: 10, marginTop: 3}}>Discount</p>
        </div> 
      // case 15:
      //   return <View style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute'}}>
      //     <Text style={{color: 'white', fontSize: scale(10), fontFamily: Fonts.bold, height: scale(10)}}>Get</Text>
      //     <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: scale(38)}}>
      //     <Text style={{color: 'white', fontSize: scale(36), fontWeight: '600', width: scale(16), fontFamily: Fonts.condensed}}>1</Text>
      //       <Text style={{color: 'white', fontSize: scale(36), fontWeight: '600', fontFamily: Fonts.condensed}}>5</Text>
      //       <Text style={{color: 'white', fontSize: scale(14), fontFamily: Fonts.condensed, position: 'absolute', left: scale(36)}}>%</Text>
      //     </View>
      //     <Text style={{color: 'white', fontSize: scale(10), fontFamily: Fonts.bold}}>Discount</Text>
      //   </View> 
      default:
        return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 95}}>
          <p style={{color: 'white', fontSize: 12, marginTop: 0, height: 10}}>Get</p>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 10}}>
            <p style={{color: 'white', fontSize: 48, fontWeight: '600', fontFamily: "CanaroMedium", height: 40}}>{discount}</p>
            <p style={{color: 'white', fontSize: 12, position: 'absolute', fontFamily: "CanaroBook", marginTop: 30, marginLeft: 40}}>%</p>
          </div>
          <p style={{color: 'white', fontSize: 10, marginTop: 20}}>Discount</p>
        </div> 
        // return <View style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute'}}>
        //   <Text style={{color: 'white', fontSize: scale(10), fontFamily: Fonts.bold, height: scale(10)}}>Get</Text>
        //   <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: scale(36)}}>
        //     {/* <Text style={{color: 'white', fontSize: scale(36), fontWeight: '600', width: scale(16), fontFamily: Fonts.condensed}}>1</Text> */}
        //     <Text style={{color: 'white', fontSize: scale(36), fontWeight: '600', fontFamily: Fonts.condensed}}>{discount}</Text>
        //     <Text style={{color: 'white', fontSize: scale(14), fontFamily: Fonts.condensed, position: 'absolute', left: discount > 9 ? scale(41) : scale(21)}}>%</Text>
        //   </View>
        //   <Text style={{color: 'white', fontSize: scale(10), fontFamily: Fonts.bold}}>Discount</Text>
        // </View>
    }
  }

  // const onDsRowPress = (rowData) => {
  //   // console.log(`rowData=${JSON.stringify(rowData)}`)
  //   // console.log(`discountTypes=${JSON.stringify(discountTypes)}`)
  //   const totalPrice = rowData.details.reduce((a,v) =>  a = a + v.quantity * v.brand.price, 0);
  //   let totalDiscount = 0;
  //   const tempDT2 = discountTypes.filter(item => item.drugstore_id == rowData.id && item.is_volume == 1 && totalPrice >= item.volume)
  //     .sort((a, b) => a.volume < b.volume ? 1 : -1)
  //     // console.log(`Dt2=${JSON.stringify(tempDT2)}`)
  //   let tempDT3 = [...tempDT2.slice(0,1), ...discountTypes.filter(item => item.drugstore_id == rowData.id && item.is_volume == 0)].sort((a, b) => a.sort_order > b.sort_order ? 1 : -1);
  //   const tempDT = tempDT3.map(item => {
  //     if (rowData.discount == 0 && item.id == 1) { //no member discount
  //       //force amount to 0 for later filtering
  //       return {
  //         ...item,
  //         amount: 0
  //       }
  //     }
  //     else {
  //       if (item.is_percentage == 1 && item.is_senior_pwd == 0) { //not senior
  //         totalDiscount += totalPrice * item.value;
  //         return {
  //           ...item,
  //           discountValue: totalPrice * item.value,
  //           subTotal: totalPrice - totalDiscount
  //         }
  //       }
  //       else if (item.is_percentage == 1 && item.is_senior_pwd == 1) { //senior
  //         const seniorDiscount = (totalPrice - totalDiscount) * item.value;
  //         totalDiscount += seniorDiscount;
  //         return {
  //           ...item,
  //           discountValue: seniorDiscount,
  //           subTotal: totalPrice - totalDiscount
  //         }
  //       }
  //       else if (item.is_percentage == 0) {
  //         totalDiscount += item.amount;
  //         return {
  //           ...item,
  //           discountValue: item.amount,
  //           subTotal: totalPrice - totalDiscount
  //         }
  //       }
  //     }
  //   });
  //   setSelectedDs(rowData);
  //   setLevel(2);
  //   setTotalPrice(totalPrice);
  //   setTotalDiscount(totalDiscount);
  //   setDiscountTypesData(tempDT);
  // }

  const onDsRowPress = (rowData) => {
    // console.log(`rowData=${JSON.stringify(rowData)}`)
    // console.log(`this.state.discountTypes=${JSON.stringify(this.state.discountTypes)}`)
    const totalPrice = rowData.details.reduce((a,v) =>  a = a + v.quantity * v.brand.price, 0);
    let totalDiscount = 0;
    // console.log(`this.state.discountTypes=${JSON.stringify(this.state.discountTypes.filter(item => item.drugstore_id == rowData.id && item.is_volume == 0))}`);
    const tempDT2 = discountTypes.filter(item => item.drugstore_id == rowData.id && item.is_volume == 1 && totalPrice >= item.volume)
      .sort((a, b) => a.volume < b.volume ? 1 : -1)
      // console.log(`Dt2=${JSON.stringify(tempDT2)}`)
    let tempDT3 = [...tempDT2.slice(0,1), ...discountTypes.filter(item => item.drugstore_id == rowData.id && item.is_volume == 0)].sort((a, b) => a.sort_order > b.sort_order ? 1 : -1);
    // console.log(`tempDT3=${JSON.stringify(tempDT3)}`);
    const tempDT = tempDT3.map(item => {
      if (item.is_percentage == 1 && totalPrice > totalDiscount + (totalPrice - totalDiscount) * item.value) { //percentage
        const seniorDiscount = (totalPrice - totalDiscount) * item.value;
        totalDiscount += seniorDiscount;
        return {
          ...item,
          discountValue: seniorDiscount,
          subTotal: totalPrice - totalDiscount,
          isUsed: true
        }
      }
      else if (item.is_percentage == 0 && totalPrice > totalDiscount + item.amount) {
        totalDiscount += item.amount;
        return {
          ...item,
          discountValue: item.amount,
          subTotal: totalPrice - totalDiscount,
          isUsed: true
        }
      }
      else {
        return {
          ...item,
          isUsed: false
        }
      }
    }).filter(item => item.isUsed);

    // console.log(`rowData=${JSON.stringify(rowData)}; totalPrice=${totalPrice}; totalDiscount: ${totalDiscount}`)
    setSelectedDs(rowData);
    setLevel(2);
    setTotalPrice(totalPrice);
    setTotalDiscount(totalDiscount);
    setDiscountTypesData(tempDT);
  }

  const renderDsItem = (rowData, index) => {
    let h = 150;

    return <div key={index} style={{display: "flex", width: w, height: h, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} >
      <div
        style={{display: 'flex', width: w * 0.8, height: h * 0.8, position: 'absolute', backgroundColor: rowData.hasBorder ? 'transparent' : '#ffcaca', borderRadius: 20, justifyContent: 'center', 
        alignItems: 'center', flexDirection: 'column', border: rowData.hasBorder ? "solid 2px #dc2828" : "solid 2px transparent"}}
        onClick={() => onDsRowPress(rowData)} 
      >
        <div style={{display: 'flex', flexDirection: 'row', width: w * 0.7, alignItems: 'center', justifyContent: 'flex-start', marginTop: 10, height: 20}}>
          <p style={{textAlign: 'center', fontWeight: '500', color: '#dc2828', fontSize: 16}} >Drugstore:</p>
          <p style={{textAlign: 'center', fontWeight: '500', color: '#404040', fontSize: 20, marginLeft: 5}} >{rowData.name}</p>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', width: w * 0.7, alignItems: 'center', justifyContent: 'flex-start', marginTop: 10, height: 20}}>
          <p style={{textAlign: 'center', fontWeight: '500', color: '#dc2828', fontSize: 16}} >Total Price:</p>
          <p style={{textAlign: 'center', fontWeight: '500', color: '#404040', fontSize: 20, marginLeft: 5}} >₱{formatNumberWithComma(rowData.total,2)}</p>
        </div>
        {rowData.discount > 0 && <div style={{display: 'flex', flexDirection: 'row', width: w * 0.7, alignItems: 'center', justifyContent: 'flex-start', marginTop: 10, height: 20}}>
            <p style={{textAlign: 'center', fontWeight: '500', color: '#dc2828', fontSize: 16}} >Discounted Price:</p>
            <p style={{textAlign: 'center', fontWeight: '500', color: '#404040', fontSize: 20, marginLeft: 5}} >₱{formatNumberWithComma(rowData.total * (100 - rowData.discount) / 100,2)}</p>
          </div>
        }
      </div>
      <div style={{position: 'absolute', marginLeft: w * 0.7, marginBottom: h * 0.48, justifyContent: 'center', alignItems: 'center'}}>
        <img src={rxSavingsTagRed} style={{width: 110, height: 110}} />
        <div style={{position: 'absolute', right: '24%', bottom: 0}}>
          {getDiscountText(rowData.discount)}
        </div>
      </div>
    </div>
  }

  const onQuantityChange = (rowData, quantity) => {
    if (Number(quantity) < 100) {
      let tempGamot = [...data
      .map(item => {
        // if (item.generic.id == rowData.generic.id && item.dosage.id == rowData.dosage.id && item.quantity < 100) {
        if (item.brand.id == rowData.brand.id) {
          return {
            ...item,
            quantity: Number(quantity)
          }
        }
        else {
          return item
        }
      })];
      setData(tempGamot);
      let tempGamot1 = [...filteredData
        .map(item => {
          // if (item.generic.id == rowData.generic.id && item.dosage.id == rowData.dosage.id && item.quantity < 100) {
          if (item.brand.id == rowData.brand.id) {
            return {
              ...item,
              quantity: Number(quantity)
            }
          }
          else {
            return item
          }
        })];
        setFilteredData(tempGamot1);
    }
  }

  const onDelete = (customProps) => {
    const rowData = customProps.item;
    const selectedSkus = [...data].filter(item => item.brand.id !== rowData.brand.id);
    setFilteredData(selectedSkus);
  }

  const renderItem = (item, index) => {
    const maxText = 35;
    const truncate = (input) => input.length > maxText ? `${input.substring(0, maxText)}...` : input;
    let name = truncate(`${item.brand.name} ${item.dosage.name} (${item.generic.name})`);
    return <div className="list-group">
      {/* <SwipeToDelete key={index} onDelete={onDelete} item={item}> */}
        <div key={index}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30, marginBottom: 5}}>
            <p style={{maxHeight: 20, left: 0, color: '#404040', fontSize: 18, width: '100%', textAlign: 'left', fontWeight: '600'}} >{name}</p>
            {/* <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginLeft: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
            <p style={{fontWeight: '500', fontSize: 11, marginLeft: 1, color: '#404040', textAlign: 'right'}}>*</p> */}
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: w * 0.95, height: 30, marginBottom: 15}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: w * 0.65}}>
              {/* <p style={{color: '#dc2828', fontSize: 18, textAlign: 'left', marginTop: 5}} >Brand:</p> */}
              <p style={{color: '#404040', fontSize: 18, textAlign: 'left', marginTop: 5}} >{item.quantity} @ ₱{formatNumberWithComma(item.brand.price, 2)}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: w * 0.35, height: 30, marginTop: 0}}>
              <p style={{color: '#404040', fontSize: 18, textAlign: 'right'}} >₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
            </div>
          </div> 
        </div>
      {/* </SwipeToDelete> */}
    </div>
  }

  const renderSelectedItem = (item, index) => {
    return <div key={index}>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30, marginBottom: 5}}>
        <p style={{left: 15, color: '#404040', fontSize: 18, width: '65%', textAlign: 'left'}} >{item.generic.name} {item.dosage.name}</p>
        <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginLeft: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.brand.price * item.quantity, 2)}</p>
        <p style={{fontWeight: '500', fontSize: 11, marginLeft: 1, color: '#404040', textAlign: 'right'}}>*</p>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: w * 0.95, height: 30, marginBottom: 15}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '60%'}}>
          <p style={{color: '#dc2828', fontSize: 18, textAlign: 'left', marginTop: 5}} >Brand:</p>
          <p style={{marginLeft: 5, color: '#404040', fontSize: 18, textAlign: 'left', marginTop: 5}} >{item.brand.name}</p>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '40%', height: 30, marginTop: 0, marginLeft: 20}}>
          <p style={{color: '#dc2828', fontSize: 18, textAlign: 'left'}} >Qty:</p>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '40%', marginTop: 0, marginLeft: 20}}>
            <div style ={{width: 25, height: 30, zIndex: 1, justifyContent: 'center', cursor: 'pointer'}} onClick={() => addQtyDs(item)}
            >
              <img src={rxPlus} style={{width: 20, height: 20, marginRight: 5, resizeMode: 'contain', marginBottom: 5}}/>
            </div> 
            <p style={{height: 18, fontSize: 18, height: 25, color: '#404040'}}>{item.quantity}</p>
            <div style ={{width: 25, height: 30, zIndex: 1, justifyContent: 'center', cursor: 'pointer'}} onClick={() => minusQtyDs(item)}
            >
              <img src={rxMinus} style={{width: 20, height: 20, marginLeft: 5, resizeMode: 'contain', marginBottom: 5}} />
            </div>
          </div>
        </div>
      </div> 
    </div>
  }

  const addQtyDs = (rowData) => {
    // console.log(`rowData=${JSON.stringify(rowData)}`)
    let tempGamot = [...ds
    .map(store => {
      // console.log(`store=${JSON.stringify(store)}`)
      let total = 0;
      const details = store.details.map(item => {
        // if (item.generic.id == rowData.generic.id && item.dosage.id == rowData.dosage.id && item.quantity < 100) {
        if (item.brand.id == rowData.brand.id && item.quantity < 100) {
          // console.log(`here`)
          total += item.brand.price * (item.quantity + 1);
          return {
            ...item,
            quantity: item.quantity + 1
          };
        }
        else {
          total += item.brand.price * item.quantity;
          return item
        }
      })
      return {
        ...store,
        details: details,
        total: total
      }
    })];
    const selected = tempGamot.filter(item => item.id == selectedDs.id)
    // console.log(`selected=${JSON.stringify(selected)}; this.state.selectedDs=${JSON.stringify(this.state.selectedDs)}`)
    if (selected.length > 0) {
      onDsRowPress(selected[0]);
      setSelectedDs(selected[0]);
      setDs(tempGamot);
    }
    addQty(rowData);
  }

  const minusQtyDs = (rowData) => {
    let tempGamot = [...ds
    .map(store => {
      let total = 0;
      const details = store.details.map(item => {
        // if (item.generic.id == rowData.generic.id && item.dosage.id == rowData.dosage.id && item.quantity > 0) {
        if (item.brand.id == rowData.brand.id && item.quantity > 0) {
          total += item.brand.price * (item.quantity - 1);
          return {
            ...item,
            quantity: item.quantity - 1
          }
        }
        else {
          total += item.brand.price * item.quantity;
          return item
        }
      })
      return {
        ...store,
        details: details,
        total: total
      }
    })];
    const selected = tempGamot.filter(item => item.id == selectedDs.id)
    if (selected.length > 0) {
      onDsRowPress(selected[0]);
      setSelectedDs(selected[0]);
      setDs(tempGamot);
    }
    minusQty(rowData);
  }

  const buy = async () => {
    const selected = selectedDs.details;
    const param = {
      total: totalPrice,
      eta: new Date(data[0].eta).toISOString().slice(0,-5), 
      deliveryFee: Number(deliveryFee),
      taxes: Number((totalPrice - totalDiscount) * taxPercentage), 
      address: userData.address,
      totalDiscount: totalDiscount,
      drugstoreId: selectedDs.id,
      details: selected.map((item) => {
        return {
          genericsId: item.generic.id,
          brandedId: item.brand.id,
          dosageId: item.dosage.id,
          quantity: item.quantity,
          price: item.brand.price
        }
      }),
      discounts: discountTypesData.filter(item => item.amount > 0).map((item) => {
        return {
          discountTypeId: item.id,
          isOneTime: item.is_one_time,
          isPercentage: item.is_percentage,
          isSeniorPwd: item.is_senior_pwd,
          amount: item.amount,
          value: item.value,
          discountValue: item.discountValue,
          subTotal: item.subTotal,
          sortOrder: item.sort_order
        }
      })
    }

    uploadBought(param)
    .then((data) => {
      setLevel(3)
    })
    .catch(e => {
        console.warn('upload bought ERROR', e.message);
    });
    setLevel(3)
  }

  const onSelectProductPress = (rowData) => {
    let result = {
      ...rowData,
      quantity: 10
    };
    if (filteredData.filter(item => item.brand.id == rowData.brand.id).length == 0) {
      const selectedSkus = [...filteredData];
      selectedSkus.push(result)
      setData(selectedSkus);
      setFilteredData(selectedSkus);
    }
    setLevel(0);
    setSearchText("");
    setProducts([]);
  }

  const renderProductItem = (rowData,index) => {
    return <div onClick={() => onSelectProductPress(rowData)}>
      <div style={{flex: 1,
        flexDirection: 'row',
        height: 70,
        alignItems: 'center',
        marginLeft: 15,
        width: width - 30, height: 40,
        cursor: 'pointer'
        }} 
      >
        <p style={{ width: width - 100, fontFamily: 'CanaroBook',
          fontSize: 16,
          color: '#dc2828',
          backgroundColor: 'transparent',
          paddingLeft: 10,
          paddingRight: 10,
          textAlign: 'left',
          marginLeft: 5
        }}>{rowData.brand.name}({rowData.generic.name}) {rowData.dosage.name}</p>
      </div>
    </div>
  }

  const w = 340;
  const h = data.length <= 3 ? 300 : 370;

  const onSearchChange = async (searchText) => {
    if (searchText.trim() == "") {
      setProducts([]);
      setSearchText(searchText);
    }
    else {
      const response = await searchBrandOrGeneric({search: encodeURI(searchText.replace(' ', '+'))});
      // console.log(`response=${JSON.stringify(response)}`)
      if (response) {
        setProducts(response.data.data);
        setSearchText(searchText);
      }
    }
  }

  const checkPhone = async () => {
    const response = await checkPhoneNumber({phoneNumber: encodeURI(`+${phoneNumber.replace("+", "")}`)});
    if (response) {
      if (response.data.data) {
        // console.log(`response.data.data=${JSON.stringify(response.data.data)}`)
        const response4 = await getUserProfile({id: response.data.data.id});
        console.log(`response4=${JSON.stringify(response4)}`)
        if (response4) {
          setUserData(response4.data.data)
        }
        setLevel(14);
        // buy(response.data.data.id)
      }
      else {
        setLevel(14)
      }
    }
  }

  const register = async () => {
    const response = await registerRx({
      firstname: firstName,
      lastname: lastName,
      displayName: `${firstName} ${lastName}`,
      phoneNumber: `+${phoneNumber}`,
      address: address,
      birthDate: `1985-01-01`,
      countryCode: 'PH'
    });
    if (response) {
      checkPhone();
    }
    else {
      alert('Error saving.  Please try again later.')
    }
  }

  return (
    <div className={classes.cartContainer}>
      {loader && <Loader />}
      {level == 0 && data.length > 0 &&
        <div className={classes.cartContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 350, maxWidth: 350, marginTop: '50px' }}>
            <>
            <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
              <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1663834242593.png' style={{width: 350, height: 87, resizeMode: 'contain'}} />
            </div>
            <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                {data.length > 0 && <div
                    style={{width: w, minHeight: h * 0.8, backgroundColor: '#ffcaca', borderRadius: 20, justifyContent: 'flex-start', alignItems: 'center'}}
                  >
                    <div style={{display: "flex", width: w * 0.95, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                      <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.05}}>
                          <div style={{minHeight: 200}}>
                            { data.map((item,index) => renderItem(item, index)) }
                          </div>
                          <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                            <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 28, marginTop: 20, height: 20}} >Total Price:</p>
                            <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 50}} >₱{formatNumberWithComma(filteredData.reduce((a,v) =>  a = a + v.quantity * v.brand.price, 0 ),2)}</p>
                          </div>
                      </div> 
                    </div>   
                  </div> 
                }
              </div>      
            </div>
            {/* <p style={{textAlign: 'left', fontWeight: '500', width: w, color: '#404040', fontSize: 12, marginTop: 5, height: 12}} >* Lowest price available</p> */}
            <p style={{textAlign: 'left', fontWeight: '500', width: w, color: '#404040', fontSize: 12, marginTop: 5, height: 12, textAlign: 'center'}} >v{packageJson.version}</p>
            {/* <div 
              style={{display: 'flex', backgroundColor: 'transparent', width: w, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', marginTop: 0}}
            >
              <MyButton label={`Select\ndrugstore`} onPress={() => onDrugstoreClick()} disabled={data.length == 0}/>
            </div> */}
            </>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            {/* { props.userId  ? <InnerPageHeader hasBackBtn goBackClick={() => navigate(routes.login)} title="" style={{position: 'absolute'}}/> :
              <InnerPageHeader hasBackBtn goBackClick={() => navigate(routes.login)} title="" style={{position: 'absolute'}}
                rightButtonLabel="Add Item"
                onRightPress={() => setLevel(12)}
              /> 
            } */}
            <InnerPageHeader hasBackBtn goBackClick={() => navigate(routes.login)} title="" style={{position: 'absolute'}}/>
          </div>
        </div>
      }
      {level == 0 && data.length == 0 && 
        <div className={classes.cartContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 350, maxWidth: 350, marginTop: '50px' }}>
            <>
            <div
              style={{width: w, minHeight: h * 0.8, backgroundColor: '#ffcaca', borderRadius: 20, justifyContent: 'flex-start', alignItems: 'center'}}
              onClick={() => navigate(routes.login)}
            >
              <img src={Placeholder} style={{width: 350, height: 622}}/>
            </div> 
            </>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => navigate(routes.login)} title="" style={{position: 'absolute'}}
              // rightButtonLabel="Add Item"
              // onRightPress={() => setLevel(12)}
            />
          </div>
        </div>
      }
      {level == 1 &&
        <div className={classes.cartContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 350, maxWidth: 350, marginTop: '50px', display: 'flex', flexDirection: 'column' }}>
            <>
            <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
              <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1663837689702.png' style={{width: 350, height: 87, resizeMode: 'contain'}} />
            </div>
            <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div style={{textDecoration: 'none', flexDirection: 'column', width: w, height: h, justifyContent: 'center', alignItems: 'center'}} >
                <div
                  style={{width: w, minHeight: h * 0.8, backgroundColor: 'transparent', borderRadius: 20, justifyContent: 'flex-start', alignItems: 'center'}}
                >
                  <div style={{display: "flex", width: w * 0.95, alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column'}}>
                    <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.0}}>
                        { ds.map((item,index) => renderDsItem(item, index)) }
                        <div 
                          style={{backgroundColor: 'transparent', width: w, alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: 0}}
                        >
                          <p style={{fontSize: 20, color: '#404040'}}>Choose one</p>
                        </div>
                    </div>
                  </div>  
                </div> 
              </div>     
            </div>
            </>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => {
              setLevel(0);
              setData([...filteredData]);
            }} title="" style={{position: 'absolute'}}/>
          </div>
        </div>
      }
      {level == 2 &&
        <div className={classes.cartContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 350, maxWidth: 350, marginTop: '50px', display: 'flex', flexDirection: 'column' }}>
            <>
            <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
              <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1663914262627.png' style={{width: 350, height: 87, resizeMode: 'contain'}} />
            </div>
            <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                <div
                  style={{width: w, minHeight: h * 0.8, backgroundColor: '#ffcaca', borderRadius: 20, justifyContent: 'flex-start', alignItems: 'center'}}
                >
                  <div style={{display: "flex", width: w * 0.95, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.05}}>
                        { selectedDs.details.map((item,index) => renderItem(item, index)) }
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 30}}>
                          <p style={{left: 10, color: '#404040', fontSize: 17, width: '65%', textAlign: 'left', fontWeight: '500'}} >TOTAL PRICE:</p>
                          <p style={{fontWeight: '500', fontSize: 20, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice,2)}</p>
                        </div>
                        {totalDiscount > 0 && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                            <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >TOTAL DISCOUNTS:</p>
                            <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, color: '#dc2828', textAlign: 'right'}}>₱{formatNumberWithComma(-totalDiscount,2)}</p>
                          </div>
                        }
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                          <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >DELIVERY FEE ({selectedDeliveryType.name}):</p>
                          <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(deliveryFee,2)}</p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                          <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >TAXES:</p>
                          <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma((totalPrice - totalDiscount) * taxPercentage,2)}</p>
                        </div>
                        <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                          <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 28, marginTop: 20, height: 20}} >Pay only:</p>
                          <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 50}} >₱{formatNumberWithComma((totalPrice - totalDiscount)*(1+taxPercentage) + Number(deliveryFee),2)}</p>
                        </div>
                    </div> 
                  </div>   
                </div> 
              </div>      
            </div>
            {/* <div 
              style={{display: 'flex', backgroundColor: 'transparent', width: w, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', marginTop: 0}}
            >
              <MyButton label={`Confirm`} onPress={() => setLevel(13)}/>
            </div> */}
            </>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            {totalDiscount > 0 ? <InnerPageHeader hasBackBtn goBackClick={() => setLevel(0)} title="" style={{position: 'absolute'}} rightButtonLabel="Discounts"
                onRightPress={() => setLevel(11)}
              /> :
              <InnerPageHeader hasBackBtn goBackClick={() => setLevel(0)} title="" style={{position: 'absolute'}} />
            }
          </div>
        </div>
      }
      {level == 3 && 
        <div className={classes.cartContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 350, maxWidth: 350, marginTop: '50px' }}>
            <>
            <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                <div
                  style={{width: w, minHeight: h * 0.8, backgroundColor: '#ffcaca', borderRadius: 20, justifyContent: 'flex-start', alignItems: 'center'}}
                >
                  <div style={{display: "flex", width: w * 0.95, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.05, marginBottom: 30}}>
                      <div style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 40, height: 30}}>You saved</p>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: 'black', fontSize: 54, height: 54}}>₱{formatNumberWithComma(totalDiscount,2)}</p>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 18}}>in your order</p>
                      </div> 
                      <div style={{flexDirection: 'column', alignItems: 'flex-start', marginTop: 60}}>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#dc2828', fontSize: 18, height: 14}}>Your Total Savings in RxTipid:</p>
                        <p style={{textAlign: 'center', fontWeight: '500', width: w * 0.9, color: '#404040', fontSize: 28, height: 28}}>₱{formatNumberWithComma(totalDiscount,2)}</p>
                      </div> 
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: 60, width: w * 0.8, marginLeft: w * 0.1}}>
                        <p style={{textAlign: 'left', fontWeight: '500', color: '#dc2828', fontSize: 16, height: 24, width: w * 0.25}}>Name: </p>
                        <p style={{textAlign: 'left', fontWeight: '500', color: '#404040', fontSize: 16, marginLeft: 5, width: w * 0.55}}>{userData.firstname} {userData.lastname}</p>
                      </div> 
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: w * 0.8, marginLeft: w * 0.1}}>
                        <p style={{textAlign: 'left', fontWeight: '500', color: '#dc2828', fontSize: 16, height: 24, width: w * 0.25}}>Address:</p>
                        <p style={{textAlign: 'left', fontWeight: '500', color: '#404040', fontSize: 16, marginLeft: 5, width: w * 0.55}}>{userData.address}</p>
                      </div>
                      {typeof userData.Referror !== 'undefined' && userData.Referror &&
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: w * 0.8, marginLeft: w * 0.1}}>
                          <p style={{textAlign: 'left', fontWeight: '500', color: '#dc2828', fontSize: 16, height: 24, width: w * 0.25}}>Referror:</p>
                          <p style={{textAlign: 'left', fontWeight: '500', color: '#404040', fontSize: 16, marginLeft: 5, width: w * 0.55}}>{userData.Referror.firstname} {userData.Referror.lastname}</p>
                        </div>
                      }
                    </div> 
                  </div>   
                </div> 
              </div>      
            </div>
            <div 
              style={{display: 'flex', backgroundColor: 'transparent', width: w, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', marginTop: 0}}
            >
              <MyButton label={`Back`} onPress={() => navigate(routes.login)}/>
            </div>
            </>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => navigate(routes.login)} title="" style={{position: 'absolute'}}/>
          </div>
        </div>
      }
      {level == 11 && 
        <div className={classes.cartContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 350, maxWidth: 350, marginTop: '50px' }}>
            <>
            <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
              <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1663922562039.png' style={{width: 350, height: 87, resizeMode: 'contain'}} />
            </div>
            <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <div style={{textDecoration: 'none', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center'}} >
                <div
                  style={{width: w, minHeight: h * 0.8, backgroundColor: 'white', border: 'solid 1px #dc2828', borderRadius: 20, justifyContent: 'flex-start', alignItems: 'center'}}
                >
                  <div style={{display: "flex", width: w * 0.95, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.05}}>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                        <p style={{left: 10, color: '#404040', fontSize: 18, width: '65%', textAlign: 'left', fontWeight: '600'}} >TOTAL PRICE:</p>
                        <p style={{fontWeight: '600', fontSize: 20, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice,2)}</p>
                      </div>
                      <div style={{marginTop: 40}}>
                        {discountTypesData.map((item, index) => {
                          return <>
                          { item.name.toUpperCase().includes('SENIOR') &&
                            <div style={{height: 20}} />
                          }
                          <div key={index} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 35}}>
                            { item.is_percentage == 1 && <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >{index+1}. {item.name.toUpperCase()} ({formatNumberWithComma(item.amount,0)}%):</p> }                    
                            { item.is_percentage == 0 && <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >{index+1}. {item.name.toUpperCase()}:</p> }                    
                            <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.discountValue,2)}</p>
                          </div>
                          {/* { index < discountTypesData.length - 1 &&
                            <div key={`st${index}`} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                              <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >Sub-Total:</p>
                              <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.subTotal,2)}</p>
                            </div>
                          } */}
                          { item.name.toUpperCase().includes('REFERRAL') &&
                            <div style={{height: 20}} />
                          }
                          </>
                        })}
                        {/* {selectedDs.discount == 0 && discountTypesData.filter(item => item.amount > 0).map((item, index) => {
                          return <><div key={index} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                            { item.is_percentage == 1 && <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >{item.name.toUpperCase()} ({formatNumberWithComma(item.amount,0)}%):</p> }                    
                            { item.is_percentage == 0 && <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >{item.name.toUpperCase()}:</p> }                    
                            <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, color: '#dc2828', textAlign: 'right'}}>₱{formatNumberWithComma(-item.discountValue,2)}</p>
                          </div>
                          { index < discountTypesData.filter(item => item.amount > 0).length - 1 &&
                            <div key={`st${index}`} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25}}>
                              <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >Sub-Total:</p>
                              <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(item.subTotal,2)}</p>
                            </div>
                          }
                          </>
                        })} */}
                        {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25, marginTop: 15}}>
                          <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >TOTAL DISCOUNTS:</p>
                          <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, color: '#dc2828', textAlign: 'right'}}>₱{formatNumberWithComma(-totalDiscount,2)}</p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: w * 0.95, height: 25, marginTop: 15}}>
                          <p style={{left: 10, color: '#404040', fontSize: 15, width: '65%', textAlign: 'left', fontWeight: '500'}} >Net Amount:</p>
                          <p style={{fontWeight: '500', fontSize: 18, width: '35%', marginRight: 5, color: '#404040', textAlign: 'right'}}>₱{formatNumberWithComma(totalPrice - totalDiscount,2)}</p>
                        </div>
                        <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                          <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 28, marginTop: 20, height: 20}} >NET AMOUNT:</p>
                          <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#404040', fontSize: 50}} >₱{formatNumberWithComma(totalPrice - totalDiscount,2)}</p>
                        </div> */}
                        <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', width: w, justifyContent: 'center', alignItems: 'center', marginTop: 0}}>
                          <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 28, marginTop: 20, height: 20}} >TOTAL DISCOUNTS:</p>
                          <p style={{textAlign: 'center', fontWeight: '500', width: w, color: '#dc2828', fontSize: 50}} >₱{formatNumberWithComma(totalDiscount,2)}</p>
                        </div>
                      </div>
                    </div> 
                  </div>   
                </div> 
              </div>      
            </div>
            <div 
              style={{display: 'flex', backgroundColor: 'transparent', width: w, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', marginTop: 0}}
            >
              <MyButton label={`Back`} onPress={() => setLevel(2)}/>
            </div>
            </>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => setLevel(2)} title="" style={{position: 'absolute'}}/>
          </div>
        </div>
      }
      {level == 12 &&
        <div className={classes.cartContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 350, maxWidth: 350, marginTop: '50px' }}>
            <>
              <div style={{marginTop: 10, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <form>
                  <input type="numeric" style={{width: w, fontSize: 16, textAlign: 'left'}} value={searchText} placeholder="Search"
                    onChange={(event) => onSearchChange(event.target.value)}
                  />
                </form>
                <div style={{textDecoration: 'none', flexDirection: 'column', width: w, height: h, justifyContent: 'center', alignItems: 'center'}} >
                  <div
                    style={{width: w, minHeight: h * 0.8, backgroundColor: 'transparent', borderRadius: 20, justifyContent: 'flex-start', alignItems: 'center'}}
                  >
                    <div style={{display: "flex", width: w * 0.95, alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column'}}>
                      <div style={{marginTop: 20, width: w * 0.95, alignItems: 'center', marginLeft: w * 0.0}}>
                          { products.map((item,index) => renderProductItem(item, index)) }
                          <div 
                            style={{backgroundColor: 'transparent', width: w, alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: 0}}
                          >
                          </div>
                      </div>
                    </div>  
                  </div> 
                </div>     
              </div>
            </>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => {
              setLevel(0);
              setProducts([]);
              setSearchText("");
            }} title="" style={{position: 'absolute'}}/>
          </div>
        </div>
      }
      {level == 13 &&
        <div className={classes.cartContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 350, maxWidth: 350, marginTop: '50px' }}>
            <>
            <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
              <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1664275445416.png' style={{width: 350, height: 147, resizeMode: 'contain'}} />
            </div>
            <div style={{marginTop: 100, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <form onSubmit={(event) => event.preventDefault()}>
                <input type="numeric" style={{width: w * 0.8, fontSize: 20, textAlign: 'center'}} value={phoneNumber} placeholder="639171234567"
                  onChange={(event) => {
                    setPhoneNumber(event.target.value.trim())
                  }} maxLength="12"
                />
              </form>
            </div>
            <div 
              style={{display: 'flex', backgroundColor: 'transparent', width: w, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', marginTop: 50}}
            >
              <MyButton label={`Confirm`} disabled={phoneNumber.length != 12} onPress={() => checkPhone()}/>
            </div>
            </>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => setLevel(2)} title="" style={{position: 'absolute'}}/>
          </div>
        </div>
      }
      {level == 14 &&
        <div className={classes.cartContainer}>
          <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', minWidth: 350, maxWidth: 350, marginTop: '50px' }}>
            <>
            <div style={{ display: "flex", backgroundColor: 'transparent', top: 30, alignItems: 'center', flexDirection: 'column' }}>
              <img src='https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1665553156801.png' style={{width: 350, height: 87, resizeMode: 'contain'}} />
            </div>
            <div style={{marginTop: 30, marginLeft: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', border: 'solid 1px #dc2828', borderRadius: 20}}>
              {/* <form onSubmit={(event) => event.preventDefault()}>
                <input type="text" style={{width: w * 0.8, fontSize: 20, textAlign: 'center'}} value={firstName} placeholder="First Name"
                  onChange={(event) => setFirstName(event.target.value)} maxLength="30"
                />
              </form>
              <form onSubmit={(event) => event.preventDefault()}>
                <input type="text" style={{width: w * 0.8, fontSize: 20, textAlign: 'center', marginTop: 30}} value={lastName} placeholder="Last Name"
                  onChange={(event) => setLastName(event.target.value)} maxLength="30"
                />
              </form>
              <form onSubmit={(event) => event.preventDefault()}>
                <textarea type="textarea" style={{width: w * 0.8, fontSize: 20, textAlign: 'center', marginTop: 30, minHeight: 60, maxHeight: 150}} value={address} placeholder="Address"
                  onChange={(event) => setAddress(event.target.value)} maxLength="100" 
                />
              </form> */}
              <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
                <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>First Name:</p>
                <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5}}>{userData.firstname}</p>
              </div>
              <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Last Name:</p>
                <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5}}>{userData.lastname}</p>
              </div>
              <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Address:</p>
                <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5}}>{userData.address}</p>
              </div>
              <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Phone #:</p>
                <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5}}>{userData.phoneNumber}</p>
              </div>
              { userData.scPwdId &&
                <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                  <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>SC/PWD ID#:</p>
                  <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5}}>{userData.scPwdId}</p>
                </div>
              }
              {typeof userData.Referror !== 'undefined' && userData.Referror &&
                <div style={{width: w * 0.8, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                  <p style={{fontSize: 16, color: '#dc2828', width: '40%', textAlign: 'left'}}>Referror:</p>
                  <p style={{fontSize: 20, color: '#404040', width: '60%', marginLeft: 5}}>{userData.Referror.firstname} {userData.Referror.lastname}</p>
                </div>
              }
            </div>
            <div 
              style={{display: 'flex', backgroundColor: 'transparent', width: w, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', marginTop: 50}}
            >
              <MyButton label={`Back`} onPress={() => setLevel(2)}/>
            </div>
            </>
          </div>
          <div style={{position: 'absolute', top: 0, paddingLeft: 0, paddingRight: 0, backgroundColor: 'black', alignItems: 'center', width: '100%'}}>
            <InnerPageHeader hasBackBtn goBackClick={() => setLevel(2)} title="" style={{position: 'absolute'}}/>
          </div>
        </div>
      }
    </div>
  );
};

export default Cart;
